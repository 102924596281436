@charset "UTF-8";
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

#ct-ultimate-gdpr-cookie-popup {
  line-height: 20px;
  box-shadow: rgb(0, 0, 0) 0 0 8px;
  border-radius: 5px;
  z-index: 999999;
  opacity: 1;
  position: fixed;
  padding: 15px 15px 0;
  width: 510px;
  font-size: 13px;
  font-weight: normal;
  text-align: left;
}
#ct-ultimate-gdpr-cookie-popup p {
  display: inline;
}
#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-change-settings,
#ct-ultimate-gdpr-cookie-popup #ct_ultimate-gdpr-cookie-reject {
  font-family: montserrat_semibold, sans-serif;
  font-size: 12px;
}
#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-read-more {
  margin-right: 10px;
}
#ct-ultimate-gdpr-cookie-popup #ct_ultimate-gdpr-cookie-reject {
  border: 1px solid;
}

#ct-ultimate-gdpr-cookie-header {
  font-weight: bolder;
  padding: 0 0 7px;
  text-align: center;
  display: block;
  margin: 0;
}

#ct-ultimate-gdpr-cookie-accept {
  float: left;
}

#ct-ultimate-gdpr-cookie-read-more {
  float: right;
}

#ct-ultimate-gdpr-cookie-change-settings {
  float: right;
}

.ct-ultimate-gdpr-container #tabs {
  border: 0;
  padding: 0;
}

.notice-info {
  padding: 15px 20px;
  margin-bottom: 15px;
  border: 1px solid #c3e6cb;
  border-radius: 2px;
  background-color: #d4edda;
  font-family: Hind, sans-serif;
  font-size: 18px;
  color: #155724;
}
.notice-info.has-error {
  border-color: #f5c6cb;
  background-color: #f8d7da;
  color: #721c24;
}

.ct-ultimate-gdpr-container #tabs .ui-widget-header {
  background: transparent;
  border: 0;
  font-family: open_sans_regular, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}
.ct-ultimate-gdpr-container #tabs .ui-widget-header li {
  font-size: 0.85em;
}

.ct-ultimate-gdpr-rounded-form.ct-ultimate-gdpr-container #tabs .ui-widget-header li {
  font-size: 14px;
}

html[dir=rtl] .ct-ultimate-gdpr-container #tabs > ul {
  float: right;
  width: 100%;
}
html[dir=rtl] .ct-ultimate-gdpr-container #tabs > ul > li {
  float: right;
}

.ct-ultimate-gdpr-container #tabs .ui-tabs-nav {
  border-radius: 0;
  padding: 0;
}

.ct-ultimate-gdpr-container #tabs .ui-tabs-nav li {
  margin: 0 0.2em 0 0;
  border-radius: 0;
}

.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-tabs-anchor {
  padding: 1em 1.5em;
  box-shadow: none;
  font-weight: 700;
  white-space: normal;
}

.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-tabs-anchor:focus {
  outline: 0;
}

.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-state-active,
.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-widget-content .ui-state-active,
.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-widget-header .ui-state-active {
  background: #f8f8f8;
  border-top: 3px solid #222;
  border-left: 0;
  border-right: 0;
}

.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-state-active a,
.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-widget-content .ui-state-active a,
.ct-ultimate-gdpr-container #tabs .ui-tabs-nav .ui-widget-header .ui-state-active a {
  color: #222;
}

.ct-ultimate-gdpr-container .ui-state-default,
.ct-ultimate-gdpr-container .ui-widget-content .ui-state-default,
.ct-ultimate-gdpr-container .ui-widget-header .ui-state-default {
  background: #ced6e0;
  border-top: 1px solid #ced6e0;
  border-left: 1px solid #ced6e0;
  border-right: 1px solid #ced6e0;
}

.ct-ultimate-gdpr-container .ui-state-hover,
.ct-ultimate-gdpr-container .ui-widget-content .ui-state-hover,
.ct-ultimate-gdpr-container .ui-widget-header .ui-state-hover {
  border-top: 1px solid #747d8c;
  border-left: 1px solid #747d8c;
  border-right: 1px solid #747d8c;
  background: #747d8c;
}

.ct-ultimate-gdpr-container .ui-state-hover a,
.ct-ultimate-gdpr-container .ui-widget-content .ui-state-hover a,
.ct-ultimate-gdpr-container .ui-widget-header .ui-state-hover a {
  color: #fff;
}

.ct-ultimate-gdpr-container #tabs .ui-tabs-panel {
  background: #f8f8f8;
  padding: 2em 1.5em;
}

.ct-ultimate-gdpr-container #tabs .ui-widget-content,
.ct-ultimate-gdpr-container label,
.ct-ultimate-gdpr-container .ui-widget input,
.ct-ultimate-gdpr-container .ui-widget select,
.ct-ultimate-gdpr-container .ui-widget textarea,
.ct-ultimate-gdpr-container .ui-widget button {
  font-family: open_sans_regular, sans-serif;
  font-size: 14px;
}

#ct-ultimate-gdpr-cookie-modal button:focus,
#ct-ultimate-gdpr-cookie-modal input[type=button]:focus,
#ct-ultimate-gdpr-cookie-modal input[type=reset]:focus,
#ct-ultimate-gdpr-cookie-modal input[type=submit]:focus {
  outline: 0 !important;
}

#ct-ultimate-gdpr-cookie-popup button:focus,
#ct-ultimate-gdpr-cookie-popup input[type=button]:focus,
#ct-ultimate-gdpr-cookie-popup input[type=reset]:focus,
#ct-ultimate-gdpr-cookie-popup input[type=submit]:focus {
  outline: 0 !important;
}

button:focus, input[type=button]:focus, input[type=reset]:focus, input[type=submit]:focus {
  outline: 0 !important;
}

.ct-headerContent {
  margin-bottom: 2em;
}

.ct-ultimate-gdpr-service-options {
  display: table;
  margin-bottom: 20px;
}

.ct-ultimate-gdpr-service-option,
.ct-ultimate-gdpr-service-details {
  display: table-cell;
  line-height: normal;
  vertical-align: top;
}

.ct-ultimate-gdpr-service-option {
  position: relative;
  width: 15px;
}

.ct-ultimate-gdpr-service-title {
  font-weight: 700;
}

.ct-ultimate-gdpr-service-description {
  font-size: 0.9em;
}

.ct-ultimate-gdpr-forget-submitBtn {
  margin-top: 2em;
  display: block;
}

.ct-ultimate-gdpr-container input[type=checkbox],
.ct-ultimate-gdpr-container input[type=radio] {
  margin: 0 0.4375em 0 0 !important;
}

.ct-ultimate-gdpr-container label[for*=ct-ultimate-gdpr-consent-] {
  position: relative;
  padding: 0 0 0 20px;
}
.ct-ultimate-gdpr-container label[for*=ct-ultimate-gdpr-consent-] input[type=checkbox] {
  position: absolute;
  top: 4px;
  left: 0;
}
.ct-ultimate-gdpr-container input[type=submit] {
  box-shadow: none;
}

.ct-ultimate-gdpr-container input[type=text],
.ct-ultimate-gdpr-container input[type=email],
.ct-ultimate-gdpr-container input[type=url],
.ct-ultimate-gdpr-container input[type=password],
.ct-ultimate-gdpr-container input[type=search],
.ct-ultimate-gdpr-container input[type=tel],
.ct-ultimate-gdpr-container input[type=number],
.ct-ultimate-gdpr-container textarea {
  background: #f7f7f7;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  color: #686868;
  padding: 0.625em 0.4375em;
  width: 100%;
  height: auto;
}

.ct-ultimate-gdpr-container input[type=submit],
.ct-ultimate-gdpr-container input[type=submit][disabled]:hover,
.ct-ultimate-gdpr-container input[type=submit][disabled]:focus {
  background: #1a1a1a;
  border: 0;
  border-radius: 2px;
  color: #fff;
  font-family: open_sans_bold, sans-serif;
  font-weight: 700;
  letter-spacing: 0.046875em;
  line-height: 1;
  padding: 0.84375em 0.875em 0.78125em;
  text-transform: uppercase;
}

.ct-ultimate-gdpr-container input[type=submit]:hover {
  background: #747d8c;
}

.ct-ultimate-gdpr-container label[for=ct-ultimate-gdpr-consent-field] {
  display: inline-block !important;
}

.ct-u-display-block {
  display: block;
}

/*Cookie Consent Modal*/
html.cookie-modal-open,
body.cookie-modal-open {
  height: initial;
  overflow: hidden !important;
}

#ct-ultimate-gdpr-cookie-modal {
  display: none;
  line-height: normal;
  position: fixed;
  z-index: 1000000;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
  box-sizing: border-box;
}

.ct-ultimate-gdpr-cookie-modal-content {
  font-family: open_sans_regular, sans-serif;
  background-color: #fff;
  margin: 10px auto 0 auto;
  padding: 30px 15px;
  width: 95%;
  max-width: 970px;
}
.ct-ultimate-gdpr-cookie-modal-content p {
  display: block !important;
}

#ct-ultimate-gdpr-cookie-modal-close,
#ct-ultimate-gdpr-cookie-modal-compact-close {
  float: right;
  background: url("images/icons8_Delete_1.png");
  width: 24px;
  height: 24px;
}

#ct-ultimate-gdpr-cookie-modal-close:hover,
#ct-ultimate-gdpr-cookie-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#ct-ultimate-gdpr-cookie-modal-body h2,
#ct-ultimate-gdpr-cookie-modal-body h3 {
  text-align: center;
  text-transform: none;
}

#ct-ultimate-gdpr-cookie-modal-body h2 {
  font-size: 30px !important;
  color: #333;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1 !important;
  font-weight: 700;
  font-family: open_sans_bold, sans-serif;
  letter-spacing: normal;
}

.ct-ultimate-gdpr-cookie-modal-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin: 0 auto;
  max-width: 620px;
  text-align: center;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-desc p {
  font-weight: 400;
}

.ct-ultimate-gdpr-cookie-modal-desc p {
  font-family: open_sans_regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 26px;
  margin: 0;
  text-transform: none;
}

.ct-ultimate-gdpr-cookie-modal-desc a {
  color: #306fce;
  line-height: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-family: open_sans_bold, sans-serif;
  font-size: 12px;
  margin: 15px 0 0 0;
  display: inline-block;
}

.ct-ultimate-gdpr-cookie-modal-desc span {
  display: block;
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 13px;
  font-weight: 800;
  color: inherit;
}

#ct-ultimate-gdpr-cookie-modal-body h3 {
  font-size: 20px !important;
  margin-top: 20px;
  margin-bottom: 40px;
  letter-spacing: normal;
  color: #333333;
  line-height: normal;
  font-family: open_sans_bold, sans-serif;
  font-weight: 600;
}

.ct-ultimate-gdpr-cookie-modal-slider {
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0 0 50px 0;
}

.ct-ultimate-gdpr-cookie-modal-slider-item {
  background-color: transparent;
}

.ct-ultimate-gdpr-cookie-modal-slider li {
  text-align: center;
  width: 20%;
  margin-left: 0 !important;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-modal-slider li:hover {
  cursor: pointer;
}

.ct-ultimate-gdpr-cookie-modal-slider li div {
  background: #fafafa;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 5px;
}

.ct-ultimate-gdpr-cookie-modal-slider li div svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.ct-ultimate-gdpr-cookie-modal-slider li div svg path {
  fill: #595959;
}

.ct-ultimate-gdpr-cookie-modal-slider li label {
  color: #595959;
  font-weight: 500 !important;
  font-size: 13px;
  letter-spacing: normal;
  margin-top: 20px !important;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block !important;
  text-transform: none;
}

.ct-ultimate-gdpr-cookie-modal-slider [type=radio]:checked,
.ct-ultimate-gdpr-cookie-modal-slider [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.ct-ultimate-gdpr-cookie-modal-slider [type=radio]:checked + label:before,
.ct-ultimate-gdpr-cookie-modal-slider [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 24px;
  margin: 0 auto;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background: #fff;
  padding: 0;
  box-sizing: border-box;
}

.ct-ultimate-gdpr-cookie-modal-slider li.ct-ultimate-gdpr-cookie-modal-slider-item--active label:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #82aa3b;
  position: absolute;
  top: 29px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  padding: 0;
  box-sizing: border-box;
}

.ct-ultimate-gdpr-cookie-modal-slider li.ct-ultimate-gdpr-cookie-modal-slider-item--NOTactive label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.ct-ultimate-gdpr-cookie-modal-slider-info {
  display: none;
}

.ct-ultimate-gdpr-cookie-modal-slider-info.ct-ultimate-gdpr-cookie-modal-slider-info--active {
  display: block;
}

.ct-ultimate-gdpr-cookie-modal-slider-desc h4 {
  font-weight: 700;
  text-align: left;
  margin: 0 0 15px 0;
  font-family: open_sans_bold, sans-serif;
  font-size: 15px;
  color: #333;
  letter-spacing: normal;
  text-transform: none;
}

.ct-clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.ct-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.ct-ultimate-gdpr-cookie-modal-slider-able,
.ct-ultimate-gdpr-cookie-modal-slider-not-able {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0;
}

.ct-ultimate-gdpr-cookie-modal-slider-able {
  padding-right: 15px;
}

.ct-ultimate-gdpr-cookie-modal-slider-able li,
.ct-ultimate-gdpr-cookie-modal-slider-not-able li {
  color: inherit;
  font-family: open_sans_regular, sans-serif;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 0 10px 0;
  padding-left: 26px;
  position: relative;
  text-align: left;
  text-transform: none;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-modal-slider-able li,
  .ct-ultimate-gdpr-cookie-modal-slider-not-able li {
    margin: 0;
    line-height: 30px;
    font-size: 13px;
  }
}

/* Cookie setting check list */
html:not([dir=rtl]) .ct-ultimate-gdpr-cookie-modal-slider-able li:before,
html:not([dir=rtl]) .ct-ultimate-gdpr-cookie-modal-slider-not-able li:before {
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: 2px;
}
html:not([dir=rtl]) .ct-ultimate-gdpr-cookie-modal-slider-able li:before {
  color: #71db80;
  content: "\f00c";
}
html:not([dir=rtl]) .ct-ultimate-gdpr-cookie-modal-slider-not-able li:before {
  color: #ffc18b;
  content: "\f00d";
}

/* [RTL]Cookie setting check list */
html[dir=rtl] .ct-ultimate-gdpr-cookie-modal-slider-able li:after,
html[dir=rtl] .ct-ultimate-gdpr-cookie-modal-slider-not-able li:after {
  font-family: FontAwesome;
  padding-left: 10px;
  top: 2px;
}
html[dir=rtl] .ct-ultimate-gdpr-cookie-modal-slider-able li:after {
  color: #71db80;
  content: "\f00c";
}
html[dir=rtl] .ct-ultimate-gdpr-cookie-modal-slider-not-able li:after {
  color: #ffc18b;
  content: "\f00d";
}

.ct-ultimate-gdpr-cookie-modal-btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.ct-ultimate-gdpr-cookie-modal-btn.save a {
  font-family: open_sans_regular, sans-serif !important;
}
.ct-ultimate-gdpr-cookie-modal-btn.save a:hover {
  border: 1px solid #3170ce !important;
  text-decoration: none;
}

.ct-ultimate-gdpr-cookie-modal-btn a {
  color: #fff;
  border-radius: 40px;
  padding: 6px 21px;
  font-weight: 600;
  background: #3170ce;
  border: 2px solid #3170ce;
  font-size: 14px;
  box-shadow: none !important;
}

.ct-ultimate-gdpr-cookie-modal-btn a:hover {
  color: #3170ce !important;
  background: none;
}

#ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-read-more {
  margin-top: 5px;
}

#ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-read-more,
#ct-ultimate-gdpr-cookie-change-settings {
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  font-weight: 400;
  padding: 6px 10px;
  cursor: pointer;
  border: 1px solid;
}

#ct-ultimate-gdpr-cookie-accept:hover,
#ct-ultimate-gdpr-cookie-read-more:hover,
#ct-ultimate-gdpr-cookie-change-settings:hover {
  opacity: 0.8;
}

.ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
  margin: 0 10px 0 0;
}

.ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-read-more {
  margin-right: 10px;
}

.ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
  margin-bottom: 20px;
}

/*Top panel*/
/*Bottom panel*/
body.cookie-modal-open #ct-ultimate-gdpr-cookie-popup {
  right: 8.5px;
}

#ct-ultimate-gdpr-cookie-popup {
  display: none;
}

.ct-ultimate-gdpr-cookie-popup-topPanel {
  padding: 20px 0 35px 0;
}

.ct-ultimate-gdpr-cookie-popup-bottomPanel {
  padding: 20px 0 35px 0;
}

#ct-ultimate-gdpr-cookie-open {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  content: "\f013";
  font-family: FontAwesome;
  font-size: 22px;
  z-index: 999999;
  cursor: pointer;
  padding: 15px;
}

.ct-ultimate-gdpr-trigger-modal-round {
  border-radius: 50%;
}

.ct-ultimate-gdpr-trigger-modal-rounded {
  border-radius: 40px;
}

.ct-ultimate-gdpr-trigger-modal-squared {
  border-radius: 0;
}

.ct-ultimate-gdpr-cookie-popup-squared {
  border-radius: 0 !important;
}

.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject {
  border-radius: 5px;
}

.ct-ultimate-gdpr-cookie-popup-button-large #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-button-large #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-button-large #ct-ultimate-gdpr-cookie-change-settings {
  padding: 10px 20px;
}

.ct-ultimate-gdpr-cookie-popup-modern {
  box-shadow: none !important;
}
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup {
  padding: 15px;
  background: rgba(255, 125, 39, 0.7490196078);
  color: white;
}
@media screen and (max-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup {
    width: 100%;
    left: 0 !important;
    right: 0 !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons {
  float: none;
}
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  font-weight: normal;
}
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content h1,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content h2,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content h3,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content h4,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content h5,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup #ct-ultimate-gdpr-cookie-content h6 {
  color: #e5e5e5;
  font-family: montserrat_semibold, sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons #ct-ultimate-gdpr-cookie-change-settings {
  font-family: montserrat_semibold, sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons #ct-ultimate-gdpr-cookie-read-more,
  .ct-ultimate-gdpr-cookie-popup-modern#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons #ct-ultimate-gdpr-cookie-change-settings {
    width: auto;
  }
}
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-topPanel, .ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-bottomPanel {
  padding: 15px 40px 0 !important;
}
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-buttons, .ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-buttons {
  padding: 0;
  text-align: right;
}
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-read-more,
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
}
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-accept, .ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-accept {
  float: left;
}
.ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-modern.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
  margin: 0 0 0 10px;
}
.ct-ultimate-gdpr-cookie-popup-modern .ct-container {
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-content {
  float: left;
}
.ct-ultimate-gdpr-cookie-popup-modern .ct-ultimate-gdpr-cookie-buttons {
  float: right;
  padding: 15px 0 5px 0;
}
.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept {
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-read-more {
  margin: 0 0 10px 0;
}
.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings {
  clear: both;
  content: "";
  display: table;
}

/**Slider **/
#ct-ultimate-gdpr-cookie-modal-slider-form {
  margin: 0;
  position: relative;
}

.ct-ultimate-gdpr-slider {
  height: 100px;
  border-radius: 70px;
  background-color: transparent;
  position: absolute;
  top: -10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.ct-ultimate-gdpr-slider-block .ct-ultimate-gdpr-slider {
  border: none;
}

.ct-ultimate-gdpr-slider-not-block .ct-ultimate-gdpr-slider {
  border: 3px solid #82aa3b;
}

.ct-ultimate-gdpr-slider-not-block .ct-ultimate-gdpr-cookie-modal-slider li#ct-ultimate-gdpr-cookie-modal-slider-item-block div svg path {
  fill: #595959 !important;
}

.ct-ultimate-gdpr-slider {
  border: 3px solid #82aa3b;
  width: 20%;
  margin-left: 20%;
}

html[dir=rtl] .ct-ultimate-gdpr-slider {
  margin-left: 0%;
  margin-right: 20%;
}

[class*=ct-ultimate-gdpr--Groups-] .ct-ultimate-gdpr-slider {
  display: none;
}

.ct-ultimate-gdpr--Groups-1 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  display: block;
  margin: 0;
  width: 100%;
}

.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  display: block;
  margin: 0;
  width: 50%;
}
.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  display: block;
  margin-left: 50%;
  width: 50%;
}

.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  display: block;
  margin: 0;
  width: 33.33%;
}
.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr--Groups-3 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  display: block;
  margin-left: 33.33%;
}
.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  width: 33.33%;
}
.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie3 li {
  width: 33.33%;
}
.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  width: 66.66%;
}

.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  display: block;
  margin: 0;
  width: 25%;
}
.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr--Groups-4 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr--Groups-4 .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
  display: block;
  margin-left: 25%;
}
.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  width: 25%;
}
.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie3 li {
  width: 25%;
}
.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  width: 50%;
}
.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie4 li {
  width: 25%;
}
.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
  width: 75%;
}

.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  display: block;
  margin: 0;
  width: 20%;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr--Groups-5 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr--Groups-5 .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr--Groups-5 .ct-slider-cookie5 .ct-ultimate-gdpr-slider {
  display: block;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  width: 20%;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie3 li {
  width: 20%;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  width: 40%;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie4 li {
  width: 20%;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
  width: 60%;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie5 li {
  width: 20%;
}
.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie5 .ct-ultimate-gdpr-slider {
  width: 80%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  margin-left: 40%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  margin-left: 12%;
  width: 25%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  margin-left: 10%;
  width: 80%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  margin-left: 5%;
  width: 25%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  margin-left: 0;
  width: 66%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  margin-left: 0;
  width: 100%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider {
  margin-left: 0;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  margin-left: 0;
  width: 25%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  margin-left: 0;
  width: 50%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  margin-left: 0;
  width: 75%;
}

.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
  margin-left: 0;
  width: 100%;
}

.ct-ultimate-gdpr-cookie-modal-slider-item--selected {
  position: relative;
}

/*
.ct-ultimate-gdpr-cookie-modal-slider-item--selected:before {
  content: "";
  position: absolute;
  border-top: 2px solid #e6e6e6;
  width: 20px;
  right: -10px;
  top: 38px;
}

.ct-ultimate-gdpr-cookie-modal-slider-item--selected:after {
  content: "";
  position: absolute;
  border-right: 2px solid #e6e6e6;
  height: 20px;
  right: -1px;
  top: 29px;
}
*/
.ct-ultimate-gdpr-cookie-topPanel-padding {
  padding-top: 154px;
}

.ct-ultimate-gdpr-cookie-bottomPanel-padding {
  padding-bottom: 154px;
}

.ct-ultimate-gdpr-cookie-topPanel-padding.popup-modern-style {
  padding-top: 114px;
}

.ct-ultimate-gdpr-cookie-bottomPanel-padding.popup-modern-style {
  padding-bottom: 114px;
}

/*Buttons*/
.ct-ultimate-gdpr-btn-cookie {
  font-weight: 400;
  padding: 6px 10px;
  cursor: pointer;
  border: 1px solid;
}

.ct-ultimate-gdpr-btn-cookie-rounded {
  border-radius: 5px;
}

.mailster-form.loadig .ct-ultimate-gdpr-consent-field .mailster-form.loadig .ct-ultimate-gdpr-consent-field {
  opacity: 0.2;
}

/*Tabs*/
@media (max-width: 640px) {
  .ct-ultimate-gdpr-container.container {
    width: auto;
  }
  .ct-ultimate-gdpr-container #tabs .ui-tabs-nav li {
    display: inline-block;
    float: none;
    width: 100%;
  }
}
/*MEDIA QUERRIES*/
@media (min-width: 480px) {
  .ct-ultimate-gdpr-cookie-modal-slider li div {
    width: 80px;
    height: 80px;
  }
  .ct-ultimate-gdpr-cookie-modal-slider li div svg {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .ct-container {
    width: 750px;
  }
  #ct-ultimate-gdpr-cookie-modal-body {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .ct-container {
    width: 970px;
  }
  .ct-ultimate-gdpr-cookie-modal-slider-desc {
    width: 100%;
  }
  #ct-ultimate-gdpr-cookie-modal-body {
    padding: 0 60px;
  }
  .ct-ultimate-gdpr-cookie-modal-slider-item--selected {
    position: relative;
  }
}
@media (min-width: 1200px) {
  .ct-container {
    width: 1170px;
  }
  .ct-ultimate-gdpr-cookie-modal-slider-desc {
    float: left;
    width: 50%;
  }
}
@media (max-width: 479px) {
  .ct-ultimate-gdpr-cookie-modal-slider li label {
    font-size: 11px;
  }
  li.ct-ultimate-gdpr-cookie-modal-slider-item label {
    font-size: 11px !important;
  }
  .ct-ultimate-gdpr-slider {
    height: 60px;
    border-radius: 40px;
    top: -8px;
  }
  .ct-ultimate-gdpr-cookie-modal-slider-item--selected:before {
    display: none;
  }
  .ct-ultimate-gdpr-cookie-modal-slider-item--selected:after {
    display: none;
  }
}
.ct-u-hidden {
  display: none !important;
}

/* MY ACCOUNT */
.ct-ultimate-gdpr-my-account form input[type=submit] {
  display: inline-block;
  vertical-align: bottom;
}
.ct-ultimate-gdpr-my-account label {
  display: block;
  line-height: normal;
  margin: 11px 0 0 0;
}
.ct-ultimate-gdpr-my-account .fa-spinner {
  -moz-animation: spin 3s linear infinite;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
  color: #6a8ee7;
  font-size: 30px;
  margin: 18px 0 0 10px;
}
.ct-ultimate-gdpr-my-account input[type=submit]:disabled {
  cursor: not-allowed;
}
.ct-ultimate-gdpr-my-account.ct-ultimate-gdpr-container .ct-checkbox {
  top: 0;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* END MY ACCOUNT */
/* DATA ACCESS FORM THEMES */
label[for=ct-ultimate-gdpr-consent-data-access] {
  display: block;
  margin-top: 15px;
}

.ct-ultimate-gdpr-container {
  /* TABS */
  /* END TABS */
  /* RECTIFICATION TEXTAREAS */
  /* END RECTIFICATION TEXTAREAS */
  /* SIMPLE */
  /* END SIMPLE */
  /* ROUNDED */
  /* END ROUNDED */
  /* TABBED */
  /* END TABBED */
}
.ct-ultimate-gdpr-container form {
  margin: 0;
}
.ct-ultimate-gdpr-container form input[type=submit] {
  display: block;
  margin-top: 15px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-my-account .ct-headerContent p {
  font-family: Hind, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #333;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-my-account #tabs .ui-widget-content input[type=submit]:disabled {
  background-color: #ccc;
  color: #999;
}
.ct-ultimate-gdpr-container #tabs .ui-widget-header {
  display: block;
}
.ct-ultimate-gdpr-container #tabs .ui-widget-header [id*=ui-id-] {
  border: 0;
  padding: 1em 0.6em;
  width: 100%;
}
.ct-ultimate-gdpr-container #tabs .ui-tabs-nav li {
  margin-right: -3px;
  vertical-align: top;
  width: 100%;
}
@media screen and (min-width: 667px) {
  .ct-ultimate-gdpr-container #tabs .ui-tabs-nav li {
    margin-right: 0;
  }
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-container #tabs .ui-tabs-nav li {
    width: auto;
  }
}
.ct-ultimate-gdpr-container #tabs-2 .ct-ultimate-gdpr-services-list .ct-ultimate-gdpr-service-options .ct-checkbox, .ct-ultimate-gdpr-container #tabs-4 .ct-ultimate-gdpr-services-list .ct-ultimate-gdpr-service-options .ct-checkbox {
  position: static;
}
.ct-ultimate-gdpr-container .ct-checkbox {
  cursor: pointer;
  height: 11px;
  left: 0;
  margin-right: 5px;
  position: absolute !important;
  top: 4px;
  width: 11px;
}
.ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:first-child, .ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:nth-child(2) {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
}
.ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:nth-child(2) {
  margin-left: 0;
  margin-top: 13px;
}
.ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email label {
  font-weight: 600;
  line-height: normal;
}
.ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email label[for*=ct-ultimate-gdpr-rectification-data-] {
  font-weight: 700;
  margin-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:first-child, .ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:nth-child(2) {
    padding: 0 0 13px 0;
    width: 48%;
  }
  .ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:first-child {
    float: left;
    margin: 0;
  }
  .ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:nth-child(2) {
    float: right;
    margin: 0;
  }
  .ct-ultimate-gdpr-container #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:nth-child(3) {
    clear: both;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form {
  margin: 0 auto;
  max-width: 770px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form input[type=email] {
  height: auto;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form input[type=submit] {
  background-color: #6a8ee7;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ct-headerContent {
  line-height: 24px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-state-default {
  background-color: transparent;
  border: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-widget-header {
  color: #333;
  font-family: montserrat_semibold, sans-serif;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-widget-header .ui-state-default {
  background-color: transparent;
  border: 0;
  font-weight: 600;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-widget-header .ui-state-default a,
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-widget-header .ui-state-default a:link {
  color: #b2b2b2;
  text-align: center;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-widget-header .ui-state-default.ui-tabs-active a {
  color: #333;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-tabs-panel {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 35px 30px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-tabs-panel.ui-widget-content {
  font-family: montserrat_regular, sans-serif;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-tabs-panel .ct-ultimate-gdpr-services-email {
  margin-bottom: 0;
  margin-top: 25px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-tabs-nav .ui-state-active {
  background-color: #fff;
  border-bottom: 4px solid #6a8ee7;
  border-top: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form #tabs .ui-tabs-nav .ui-tabs-anchor {
  font-weight: 600;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content .ui-widget-content a {
  color: #4c5e8a;
  text-decoration: underline;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content label {
  color: #808080;
  font-size: 12px;
  font-weight: 600;
  font-family: montserrat_semibold, sans-serif;
  line-height: normal;
  margin: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content label[for*=ct-ultimate-gdpr-consent-] {
  display: inline-block;
  font-weight: 300;
  margin-top: 10px;
  padding-left: 16px;
  position: relative;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content label[for=ct-ultimate-gdpr-email] {
  font-weight: 600;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content input[type=email],
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content textarea {
  background-color: #f2f2f2;
  border: 0;
  border-radius: 4px;
  color: #b8b8b8;
  font-size: 12px;
  font-weight: 300;
  height: auto;
  margin: 0;
  padding: 10px 14px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content input[type=submit] {
  background-color: #6a8ee7;
  border-radius: 3px;
  box-shadow: 0 0;
  margin: 10px 0 0 0;
  padding: 13px 28px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content input[type=checkbox] {
  opacity: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content .ct-checkbox {
  border: 2px solid #d9d8d8;
  display: inline-block;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content .ct-checkbox.ct-checked {
  background-color: #6a8ee7;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content .ui-state-default {
  background-color: transparent;
  border: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content #tabs-1 #ct-ultimate-gdpr-email {
  margin-top: 5px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content #tabs-2 #ct-ultimate-gdpr-forget-email {
  margin-top: 5px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content #tabs-2 label[for=ct-ultimate-gdpr-consent-forget-me] {
  display: inline-block;
  margin-top: 10px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content #tabs-3 .ct-headerContent {
  margin-bottom: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content #tabs-3 #ct-ultimate-gdpr-rectify-email {
  margin-top: 5px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content #tabs-3 .ct-ultimate-gdpr-services-email:nth-child(3) {
  margin: 15px 0 0 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content .ct-headerContent {
  color: #666666;
  font-size: 13.5px;
  font-weight: 600;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content .ct-ultimate-gdpr-service-title {
  color: #333;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-simple-form .ui-widget-content .ct-ultimate-gdpr-service-description {
  color: #808080;
  font-size: 10px;
  margin-top: 7px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form {
  margin: 0 auto;
  max-width: 770px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-widget-header {
  background-color: #ebebeb;
  border-radius: 25px;
  font-family: Hind, sans-serif;
  line-height: normal;
  margin-bottom: 15px;
  padding: 5px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-widget-header [id*=ui-id-]:before {
  padding: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-widget-header .ui-state-default {
  background-color: transparent;
  border: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-nav .ui-state-active {
  background-color: #737373;
  border: 0;
  border-radius: 25px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-nav .ui-state-active a {
  color: #fff;
  font-weight: 500;
  padding: 9px 0 5px 0;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-nav .ui-state-active a {
    padding: 9px 20px 5px 20px;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-nav .ui-state-active a:before {
  color: #fff;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-nav .ui-tabs-anchor {
  color: #737373;
  font-weight: 600;
  padding: 9px 15px 6px 15px;
  text-align: center;
  text-transform: capitalize;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-panel {
  background-color: transparent;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-family: Hind, sans-serif;
  padding: 20px;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-panel {
    padding: 40px 30px;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-panel .ct-ultimate-gdpr-services-email {
  margin-bottom: 0;
  margin-top: 3px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-panel .ct-ultimate-gdpr-services-email:nth-child(3) {
  margin-top: 20px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form #tabs .ui-tabs-panel label[for*=ct-ultimate-gdpr-consent-] {
  display: inline-block;
  margin: 8px 0 10px 0;
  padding-left: 16px;
  position: relative;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content label {
  color: #808080;
  display: inline;
  font-family: Hind, sans-serif;
  font-size: 12px;
  font-weight: 700;
  margin: 0 5px 0 0;
  text-transform: uppercase;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content label:nth-of-type(2) {
  font-size: 13px;
  font-weight: 300;
  text-transform: none;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content label a {
  border: 0;
  color: #788fc6;
  text-decoration: underline;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #ct-ultimate-gdpr-age-guard-name {
  display: inline;
  width: auto;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #ct-ultimate-gdpr-age-guard-name + label {
  position: relative;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #ct-ultimate-gdpr-age-guard-name + label:before {
  content: "";
  display: block;
  margin-top: 20px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content input[type=email],
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content textarea {
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #b8b8b8;
  font-family: Hind, sans-serif;
  font-size: 13px;
  height: auto;
  margin: 0;
  padding: 8px 18px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content input[type=submit] {
  background-color: #6a8ee7;
  border-radius: 25px;
  box-shadow: 0 0;
  font-family: Hind, sans-serif;
  font-weight: 600;
  margin: 20px 0 0 0;
  padding: 8px 40px 5px 40px;
  line-height: normal;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content .ct-checkbox {
  border: 2px solid #d9d8d8;
  border-radius: 50%;
  display: inline-block;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content .ct-checkbox.ct-checked {
  background-color: #6a8ee7;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content input[type=checkbox] {
  opacity: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content .ct-headerContent {
  color: #666666;
  font-size: 16px;
  margin-bottom: 30px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content .ct-ultimate-gdpr-service-title {
  font-size: 16px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content .ct-ultimate-gdpr-service-description {
  color: #808080;
  font-size: 14px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #tabs-2 label {
  margin: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #tabs-2 label[for=ct-ultimate-gdpr-consent-forget-me] {
  font-size: 13px;
  font-weight: 300;
  text-transform: none;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #tabs-2 label[for=ct-ultimate-gdpr-consent-data-rectification] {
  font-size: 13px;
  font-weight: 300;
  text-transform: none;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #ct-ultimate-gdpr-rectification label[for=ct-ultimate-gdpr-rectification-data-current] {
  margin-bottom: 5px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form .ui-widget-content #ct-ultimate-gdpr-rectification label[for=ct-ultimate-gdpr-consent-data-rectification] {
  font-size: 13px;
  font-weight: 300;
  text-transform: none;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-rounded-form.ct-ultimate-gdpr-my-account .ct-checkbox {
  top: 4px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form {
  font-family: open_sans_regular, sans-serif;
  margin: 0 auto;
  max-width: 770px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-state-default {
  background-color: #e9e8e8;
  border: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-state-default {
    margin: 0 3px 0 0;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav.ui-widget-header {
  font-family: open_sans_regular, sans-serif;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-tabs-anchor {
  font-weight: 700;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-state-active {
  background-color: #f8f8f8;
  border: 0;
  padding: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-state-active li {
  background-color: #e9e8e8;
  border: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: open_sans_bold, sans-serif;
  font-weight: 700;
  margin-right: 3px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-state-active a {
  color: #3e71d1;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-widget-header .ui-state-active a {
  color: #3e71d1;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-tabs-nav .ui-widget-header {
    display: inline-block;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-header [id*=ui-id-] {
  color: #ccc;
  text-align: center;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content {
  font-family: open_sans_regular, sans-serif;
  padding: 35px 30px 40px 30px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content.ui-widget button,
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content.ui-widget input,
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content.ui-widget select,
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content.ui-widget textarea {
  font-family: open_sans_regular, sans-serif;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content label[for*=ct-ultimate-gdpr-consent-] {
  color: #808080;
  display: inline-block;
  font-family: open_sans_regular, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 18px 0;
  padding-left: 16px;
  position: relative;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content label[for=ct-ultimate-gdpr-email] {
  font-family: open_sans_bold, sans-serif;
  font-weight: 700;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content label a {
  color: #31518f;
  text-decoration: underline;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content input[type=email] {
  background-color: #fff;
  border: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  box-shadow: none;
  color: #b8b8b8;
  display: inline-block;
  font-size: 13px;
  height: auto;
  line-height: normal;
  margin: 0 -4px 0 0;
  padding: 12px;
  width: 100%;
}
@media screen and (min-width: 414px) {
  .ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content input[type=email] {
    width: 77%;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content input[type=submit] {
  background-color: #3e71d1;
  font-weight: 600;
  margin: 15px 0 0 0;
  padding: 14px 0;
  width: 100%;
  display: block;
  border-radius: 0;
}
@media screen and (min-width: 414px) {
  .ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content input[type=submit] {
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 3px;
    display: inline-block;
    width: 22%;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content input[type=checkbox] {
  opacity: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-headerContent {
  color: #666666;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 21px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ui-state-active a {
  color: #3e71d1;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-ultimate-gdpr-services-email {
  margin: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-ultimate-gdpr-services-email label[for*=ct-ultimate-gdpr-rectification-data-] {
  font-family: open_sans_bold, sans-serif;
  font-weight: 700;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-ultimate-gdpr-services-email textarea {
  background-color: #ffffff;
  border: 0;
  box-shadow: none;
  margin: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-ultimate-gdpr-services-email:nth-child(3) {
  margin-top: 29px;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-checkbox {
  border: 2px solid #cacaca;
  border-radius: 2px;
  display: inline-block;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-checkbox.ct-checked {
  background-color: #3e71d1;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs .ui-widget-content .ct-ultimate-gdpr-forget-submitBtn {
  margin-top: 0;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs #tabs-2 .ct-ultimate-gdpr-services-email label {
  margin: 10px 0 0 0;
  display: inline-block;
  font-weight: 800;
  font-family: open_sans_bold, sans-serif;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs #tabs-3 #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:nth-child(2) {
  margin: 13px 0 0 0;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form #tabs #tabs-3 #ct-ultimate-gdpr-rectification .ct-ultimate-gdpr-services-email:nth-child(2) {
    margin: 0;
  }
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form label[for^=ct-ultimate-gdpr-] {
  font-family: open_sans_bold, sans-serif;
}
.ct-ultimate-gdpr-container.ct-ultimate-gdpr-tabbed-form .ct-checkbox {
  top: 4px;
}

/* UNSUBSCRIBE */
.ct-ultimate-gdpr-rounded-form #ct-ultimate-gdpr-unsubscribe label[for=ct-ultimate-gdpr-consent-unsubscribe] {
  font-size: 13px;
  font-weight: 300;
  text-transform: none;
}

#ct-ultimate-gdpr-unsubscribe-email {
  margin-top: 5px;
}

/* END UNSUBSCRIBE */
/* END DATA ACCESS FORM THEMES */
/* Protection Blur */
.ct-ultimate-gdpr-shortcode-protection.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: #ccc;
  overflow-wrap: break-word;
}

.ct-ultimate-gdpr-shortcode-protection-label {
  position: absolute;
  z-index: 9999;
}

/*******
  Modal Skin Style 1
  *******/
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body h2 {
  font-weight: 500;
  line-height: normal !important;
  font-family: Hind, sans-serif;
  letter-spacing: normal;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body h2 {
    font-size: 26px;
  }
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body h3 {
  font-size: 22px !important;
  font-weight: 500;
  font-family: Hind, sans-serif;
  letter-spacing: normal;
  line-height: normal;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body p {
  color: inherit;
  font-size: 15px;
  font-weight: 400;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-desc {
  margin: 0 auto;
  max-width: 615px;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-desc a {
  color: #4467b9;
  display: inline-block;
  font-size: 15px;
  margin: 15px 0 10px 0;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider {
  display: inline-block;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider li {
  margin: 1px !important;
  float: left;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider {
    display: flex;
  }
  .ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider li {
    margin: 0 5px !important;
  }
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body #ct-ultimate-gdpr-cookie-modal-slider-form {
  margin: 0;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap .ct-ultimate-gdpr-cookie-modal-slider-info {
  padding: 0 10px;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap .ct-ultimate-gdpr-cookie-modal-slider-info .ct-ultimate-gdpr-cookie-modal-slider-desc h4 {
  color: #666;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
  margin: 0 0 15px 0;
  letter-spacing: normal;
  font-family: Hind, sans-serif;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap .ct-ultimate-gdpr-cookie-modal-slider-info .ct-ultimate-gdpr-cookie-modal-slider-desc li {
  color: inherit;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap .ct-ultimate-gdpr-cookie-modal-slider-info .ct-ultimate-gdpr-cookie-modal-slider-desc li:before {
  color: #436621;
  content: "—";
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap .ct-ultimate-gdpr-cookie-modal-slider-info .ct-ultimate-gdpr-cookie-modal-slider-desc .ct-ultimate-gdpr-cookie-modal-slider-able {
  padding-right: 35px;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-btn a {
  background-color: #4265b8;
  border: 2px solid #4265b8;
  border-radius: 0;
  font-family: Hind, sans-serif !important;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-skin-one #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-btn a:hover {
  background-color: #fff;
  color: #4265b8;
  text-decoration: none;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-slider {
  border: 0;
}
.ct-ultimate-gdpr-cookie-skin-one.ct-ultimate-gdpr-cookie-modal-content {
  font-family: Hind, sans-serif;
}
.ct-ultimate-gdpr-cookie-skin-one li.ct-ultimate-gdpr-cookie-modal-slider-item.ct-ultimate-gdpr-cookie-modal-slider-item--active, .ct-ultimate-gdpr-cookie-skin-one li.ct-ultimate-gdpr-cookie-modal-slider-item.ct-ultimate-gdpr-cookie-modal-slider-item--selected {
  background: #f2f2f2;
  border-radius: 2px;
  border: 1px solid #ccc;
}
.ct-ultimate-gdpr-cookie-skin-one li.ct-ultimate-gdpr-cookie-modal-slider-item.ct-ultimate-gdpr-cookie-modal-slider-item--active path, .ct-ultimate-gdpr-cookie-skin-one li.ct-ultimate-gdpr-cookie-modal-slider-item.ct-ultimate-gdpr-cookie-modal-slider-item--selected path {
  fill: #9599a1 !important;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:not(:checked) + label:before {
  display: none;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider li.ct-ultimate-gdpr-cookie-modal-slider-item--active label:after {
  display: none;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider-item--selected:before, .ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider-item--selected:after {
  display: none;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-slider-not-block .ct-ultimate-gdpr-slider {
  border: 0;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider li {
  margin: 0 20px;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider li label {
  font-size: 15px;
  font-weight: 600 !important;
  margin: 20px 0 0 0 !important;
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider-item {
  background: rgba(242, 242, 242, 0.5);
  padding: 35px 0;
  border: 2px solid rgba(34, 49, 86, 0);
}
.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider li div {
  background: #fcfcfc;
}
.ct-ultimate-gdpr-cookie-skin-one path {
  fill: rgba(149, 153, 161, 0.2) !important;
}
@media (min-width: 800px) {
  .ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider li div {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 400px) {
  .ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider li div {
    width: 35px;
    height: 35px;
    margin: 10px auto 0;
  }
}
@media (max-width: 600px) {
  .ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider-item {
    padding: 15px 0 !important;
  }
}
@media (max-width: 400px) {
  .ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider-item {
    padding: 0px 0 !important;
  }
}

@media (max-width: 600px) {
  .ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider li {
    margin: 0 5px !important;
  }
}
/*******
  Modal Skin Style 2
  *******/
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content {
  font-family: open_sans_regular, sans-serif;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body h2 {
  font-size: 32px;
  font-weight: 800;
  font-family: open_sans_bold, sans-serif;
  letter-spacing: normal;
  line-height: normal !important;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body h3 {
  font-family: montserrat_medium, sans-serif;
  font-size: 24px !important;
  font-weight: 500;
  line-height: 32px !important;
  margin: 20px auto 40px auto;
  max-width: 685px;
  letter-spacing: normal;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body h4 {
  color: #333;
  font-family: montserrat_medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 15px 0;
  line-height: normal;
  letter-spacing: normal;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body #ct-ultimate-gdpr-cookie-modal-slider-form {
  margin: 0;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider {
  display: inline-block;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider li {
  float: left;
  margin: 2px;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider {
    display: flex;
  }
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-desc p {
  color: inherit;
  font-size: 15px;
  line-height: 25px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-desc a {
  color: #6a8ee7;
  display: inline-block;
  font-weight: 700;
  margin: 20px 0 35px 0;
  font-size: 13px;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-slider-wrap .ct-ultimate-gdpr-cookie-modal-slider-able li:before {
  color: #84cf3b;
  content: "\f058";
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-btn a {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-family: montserrat_regular, sans-serif;
  font-size: 14px;
  text-transform: none;
  background-color: #6a8ee7;
  border: 2px solid #6a8ee7;
  padding: 11px 18px 11px 14px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-btn a:before {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  margin-right: 10px;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-btn a:hover {
  color: #fff;
  background-color: #fff;
  text-decoration: none;
}
.ct-ultimate-gdpr-cookie-skin-two.ct-ultimate-gdpr-cookie-modal-content #ct-ultimate-gdpr-cookie-modal-body .ct-ultimate-gdpr-cookie-modal-btn a:hover:before {
  color: #6a8ee7;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li {
  -webkit-transition: all 0.75s ease;
  transition: all 0.75s ease;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li :after {
  content: "";
  width: 0% !important;
  height: 6px;
  background: #6a8ee7 !important;
  position: absolute;
  top: 50px !important;
  left: 0;
  right: auto !important;
  margin: 0 auto;
  border-radius: 0 !important;
  -webkit-transition: all 0.75s ease;
  transition: all 0.75s ease;
  opacity: 0;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li.ct-ultimate-gdpr-cookie-modal-slider-item--active label:after, .ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li.ct-ultimate-gdpr-cookie-modal-slider-item--selected label:after {
  width: 100% !important;
  opacity: 1;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li.ct-ultimate-gdpr-cookie-modal-slider-item--active label:after {
  transition: all 1s ease;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  margin: 0 auto;
  width: 100%;
  height: 5px;
  border: 0;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-slider-not-block .ct-ultimate-gdpr-slider {
  border: 0;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider-item--selected:before, .ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider-item--selected:after {
  border: 0;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-slider {
  border: 0;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider-item--selected,
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider-item--active {
  background: #f2f2f2 !important;
  border-radius: 0;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li div {
  background: transparent;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li div svg {
  top: 80%;
  left: 50%;
  -webkit-transform: translateY(-80%) translateX(-50%);
  transform: translateY(-80%) translateX(-50%);
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li label {
  color: #4c4c4c;
  font-family: montserrat_regular, sans-serif;
  margin: 15px 0 0 0 !important;
  line-height: 30px;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider-item {
  height: 150px;
  background-color: transparent;
}
@media (max-width: 478px) {
  .ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider-item {
    height: 110px;
  }
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:not(:checked) + label:before {
  background: #f2f2f2;
  border-radius: 0 !important;
}
.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-slider li div svg path {
  fill: #878b92 !important;
}
@media (min-width: 992px) {
  .ct-ultimate-gdpr-cookie-skin-two #ct-ultimate-gdpr-cookie-modal-slider-form {
    margin-left: -75px !important;
    margin-right: -75px !important;
  }
}
@media (min-width: 768px) {
  .ct-ultimate-gdpr-cookie-skin-two #ct-ultimate-gdpr-cookie-modal-slider-form {
    margin-left: -35px;
    margin-right: -35px;
    margin-bottom: 0;
  }
}

.um-field .ct-ultimate-gdpr-consent-field {
  font-size: 14px !important;
}
.um-field input[type=checkbox].ct-ultimate-gdpr-consent-field {
  margin: 4px 0 4px !important;
}

/* COOKIE MODAL COMPACT */
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-content {
  bottom: 0;
  left: 0;
  letter-spacing: normal;
  margin: 0;
  max-height: 100%;
  max-width: none;
  overflow-y: scroll;
  padding: 15px;
  position: fixed;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue #ct-ultimate-gdpr-cookie-modal-close {
  position: relative;
  z-index: 1000001;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-slider-block,
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-slider-not-block {
  margin: 0 auto;
  max-width: 770px;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue #ct-ultimate-gdpr-cookie-modal-body {
  padding: 0;
  position: relative;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue #ct-ultimate-gdpr-cookie-modal-body h2 {
  clear: none;
  font-family: Hind, sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal !important;
  margin-bottom: 5px;
  text-align: left;
  text-transform: uppercase;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-desc {
  font-size: 11px;
  line-height: 18px;
  max-width: none;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-desc * {
  color: inherit;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-desc a {
  color: #4265b8;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-desc p {
  font-family: Hind, sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue h3 {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue #ct-ultimate-gdpr-cookie-modal-slider-form {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue #ct-ultimate-gdpr-cookie-modal-slider-form .ct-ultimate-gdpr-slider {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider li {
  background-color: #fff;
  float: left;
  list-style-type: none;
  margin-left: 0 !important;
  padding: 8px 15px 11px 40%;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider .hide-btn-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li div {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li label {
  color: #737373;
  font-family: Hind, sans-serif;
  font-size: 11px;
  font-weight: 600 !important;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 !important;
  position: relative;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item {
  background-color: #fff;
  margin: 0;
  text-align: left;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active, .ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected {
  background-color: #f2f2f2;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected:after, .ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected:before {
  border: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected label:after {
  background: 0 0;
  color: #bbb;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 12px;
  height: auto;
  left: -17px;
  margin: 0;
  position: absolute;
  right: auto;
  top: 2px;
  -webkit-transform: none;
  transform: none;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active [type=radio]:not(:checked) + label:before {
  border: 1px solid #84cf3b;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active label:after {
  background: 0 0;
  font-family: FontAwesome;
  color: #84cf3b;
  content: "\f00c";
  font-size: 12px;
  height: auto;
  left: -17px;
  margin: 0;
  right: auto;
  top: 2px;
  -webkit-transform: none;
  transform: none;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-modal-compact-dark-blue [type=radio]:not(:checked) + label:before {
  background-color: transparent;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 11px;
  left: -19px;
  margin: 0;
  top: 4px;
  width: 11px;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .hide-btn {
  background-color: #bbb;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-family: Hind, sans-serif;
  font-size: 11px;
  font-weight: 400;
  height: auto;
  letter-spacing: normal;
  padding: 10px 10px 11px 10px;
  text-transform: none;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .fa {
  font-size: 8px;
  margin-left: 3px;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-btn.compact {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-wrap {
  border: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: auto;
  overflow: visible;
  padding: 0 0 15px 0;
  position: relative;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .cookie-modal-tab-wrapper {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .cookie-modal-tab-wrapper li {
  background-color: #f2f2f2;
  cursor: pointer;
  display: table;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0 4px 0 15px;
  text-align: left;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .cookie-modal-tab-wrapper li:not(:first-child) {
  border-top: 1px solid #fff;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .cookie-modal-tab-wrapper li:last-child {
  border-bottom-left-radius: 5px;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .cookie-modal-tab-wrapper li span {
  display: table-cell;
  height: 26px;
  padding: 0;
  vertical-align: middle;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-active-tab {
  background-color: #4265b8;
  color: #fff;
  font-weight: 400;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-active-tab span {
  color: #fff !important;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap {
  display: block !important;
  height: auto;
  margin: 0;
  overflow-y: scroll;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-info {
  height: 100%;
  padding: 0 20px;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-desc:first-child h4 {
  color: #84cf3b;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-desc:nth-child(2) h4 {
  color: #f64d40;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-desc li:not(:first-child) {
  margin-top: 3px;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue h4 {
  font-family: Hind, sans-serif;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  margin: 6px 0 8px 0;
  text-transform: uppercase;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-able,
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-not-able {
  margin: 0;
  padding-left: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-able li,
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-not-able li {
  color: inherit;
  font-family: Hind, sans-serif;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-able li:before,
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-not-able li:before {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-btn {
  display: block;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue a {
  background: 0 0;
  border-radius: 0;
  border: 1px solid #4265b8;
  display: block;
  font-size: 0;
  padding: 0;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue a:after {
  background-color: #4265b8;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  content: "OK";
  display: inline-block;
  font-size: 12px;
  font-family: Hind, sans-serif;
  letter-spacing: normal;
  padding: 6px 25px;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-dark-blue a:hover:after {
  background-color: transparent;
  border: none;
  color: #4265b8;
}

.ct-ultimate-gdpr-cookie-modal-compact-light-blue * {
  color: inherit;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue h3 {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue p {
  font-family: open_sans_regular, sans-serif;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 17px;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-content {
  bottom: 0;
  left: 0;
  letter-spacing: normal;
  margin: 0;
  max-height: 100%;
  max-width: none;
  overflow-y: scroll;
  padding: 15px;
  position: fixed;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue #ct-ultimate-gdpr-cookie-modal-close {
  position: relative;
  z-index: 1000001;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-slider-not-block,
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-slider-block {
  margin: 0 auto;
  max-width: 770px;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue #ct-ultimate-gdpr-cookie-modal-body {
  padding: 0;
  position: relative;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue #ct-ultimate-gdpr-cookie-modal-body h2 {
  clear: none;
  font-family: open_sans_regular, sans-serif;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal !important;
  margin-bottom: 5px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-desc {
  font-size: 11px;
  line-height: 18px;
  margin: 0;
  max-width: 470px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue #ct-ultimate-gdpr-cookie-modal-slider-form {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue #ct-ultimate-gdpr-cookie-modal-slider-form .ct-ultimate-gdpr-slider {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider li {
  background-color: #fff;
  float: left;
  list-style-type: none;
  margin-left: 0 !important;
  padding: 8px 15px 8px 40%;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider li div {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider li label {
  color: #737373;
  font-family: Montserrat, sans-serif;
  font-size: 10px;
  font-weight: 700 !important;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 !important;
  position: relative;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider li:before {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider [type=radio]:not(:checked) + label:before {
  background-color: transparent;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 11px;
  left: -17px;
  margin: 0;
  top: 1px;
  width: 11px;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider .hide-btn-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider .hide-btn {
  background-color: #bbbbbb;
  border-bottom: 1px solid #bbbbbb;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-family: open_sans_regular, sans-serif;
  font-size: 11px;
  font-weight: 400;
  height: auto;
  letter-spacing: normal;
  padding: 10px 13px 13px 13px;
  text-transform: none;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item {
  background-color: #fff;
  border-bottom: 2px solid #ebebeb;
  margin: 0;
  text-align: left;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected,
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active {
  background-color: #f2f2f2;
  border-bottom: 2px solid #6a8ee7;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected:before, .ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected:after {
  border: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--selected label:after {
  -webkit-transform: none;
  background: none;
  color: #bbbbbb;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 7px;
  height: auto;
  left: -15px;
  margin: 0;
  position: absolute;
  right: auto;
  top: 3px;
  transform: none;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active [type=radio]:not(:checked) + label:before {
  border: 2px solid #9fd5a0;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item--active label:after {
  background: none;
  color: #84cf3b;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 7px;
  height: auto;
  left: -15px;
  margin: 0;
  right: auto;
  top: 3px;
  -webkit-transform: none;
  transform: none;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .fa {
  font-size: 8px;
  margin-left: 3px;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-btn.compact {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-wrap {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0;
  height: auto;
  overflow: visible;
  padding: 0 0 15px 0;
  position: relative;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .cookie-modal-tab-wrapper {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .cookie-modal-tab-wrapper li {
  background-color: #f2f2f2;
  cursor: pointer;
  display: table;
  font-family: Montserrat, sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0 4px 0 15px;
  text-align: left;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .cookie-modal-tab-wrapper li:not(:first-child) {
  border-top: 1px solid #fff;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .cookie-modal-tab-wrapper li:last-child {
  border-bottom-left-radius: 5px;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .cookie-modal-tab-wrapper li span {
  display: table-cell;
  height: 26px;
  padding: 0;
  vertical-align: middle;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-active-tab {
  background-color: #6a8ee7;
  color: #fff;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-active-tab span {
  color: #fff !important;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap {
  display: block !important;
  height: auto;
  margin: 0;
  overflow-y: scroll;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-info {
  height: 100%;
  padding: 0 20px;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-desc:first-child h4 {
  color: #84cf3b;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-desc:nth-child(2) h4 {
  color: #f64d40;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-desc li:not(:first-child) {
  margin-top: 6px;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-desc li:before {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue h4 {
  font-family: Montserrat, sans-serif;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: normal;
  margin: 6px 0 8px 0;
  text-transform: uppercase;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-able,
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-not-able {
  margin: 0;
  padding-left: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-able li,
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-not-able li {
  color: inherit;
  font-family: montserrat_bold, sans-serif;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 12px;
  margin: 0;
  padding: 0;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-btn {
  display: block;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue a {
  background: transparent;
  border-radius: 0;
  border: 0;
  display: block;
  font-size: 0;
  padding: 0;
  position: relative;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue a:after {
  background-color: #6a8ee7;
  border: 1px solid #6a8ee7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  content: "Ok";
  display: inline-block;
  font-family: montserrat_regular, sans-serif;
  font-size: 12px;
  letter-spacing: normal;
  padding: 6px 25px;
  text-transform: capitalize;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-light-blue a:hover:after {
  background-color: transparent;
  border: none !important;
  color: #4265b8;
}

.ct-ultimate-gdpr-cookie-modal-compact-green * {
  color: inherit;
}
.ct-ultimate-gdpr-cookie-modal-compact-green h3 {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li div {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li label {
  color: #595959;
  font-family: open_sans_regular, sans-serif;
  font-size: 10px;
  font-weight: 500 !important;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 !important;
  position: relative;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green p {
  font-family: open_sans_regular, sans-serif;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 17px;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-slider {
  border: 2px solid #82aa3b;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  display: block;
  height: 40px;
  margin: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  display: block;
  height: 73px;
  margin: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  display: block;
  height: 107px;
  margin: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
  display: block;
  height: 141px;
  margin: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-slider {
  -webkit-transition: all 0.3s;
  background-color: transparent;
  border-radius: 70px;
  display: none;
  height: 40px;
  position: absolute;
  top: -2px;
  transition: all 0.3s;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
  display: block;
  margin: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
  display: block;
  height: 40px;
  margin: 33px 0 0 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
  display: block;
  height: 74px;
  margin: 33px 0 0 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
  display: block;
  height: 108px;
  margin: 33px 0 0 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie5 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie5 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie5 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie5 .ct-ultimate-gdpr-slider {
  display: block;
  height: 142px;
  margin: 33px 0 0 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-content {
  bottom: 0;
  left: 0;
  letter-spacing: normal;
  margin: 0;
  max-height: 100%;
  max-width: none;
  overflow-y: scroll;
  padding: 15px;
  position: fixed;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green #ct-ultimate-gdpr-cookie-modal-close {
  position: relative;
  z-index: 1000001;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-slider-not-block,
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-slider-block {
  margin: 0 auto;
  max-width: 770px;
}
.ct-ultimate-gdpr-cookie-modal-compact-green #ct-ultimate-gdpr-cookie-modal-body {
  padding: 0;
  position: relative;
}
.ct-ultimate-gdpr-cookie-modal-compact-green #ct-ultimate-gdpr-cookie-modal-body h2 {
  clear: none;
  font-family: open_sans_regular, sans-serif;
  font-size: 14px !important;
  font-weight: 800;
  letter-spacing: normal;
  line-height: normal !important;
  margin: 0 0 5px 0;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-desc {
  font-size: 11px;
  line-height: 18px;
  margin: 0;
  max-width: 470px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-green #ct-ultimate-gdpr-cookie-modal-slider-form {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider li {
  background-color: #fff;
  float: left;
  list-style-type: none;
  margin-left: 0 !important;
  padding: 8px 15px 8px 40%;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item {
  background-color: #fff;
  margin: 0;
  min-width: 104px;
  text-align: left;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--selected, .ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--active {
  background-color: transparent;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--selected:before, .ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--selected:after {
  border: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--selected label:after {
  -webkit-transform: none;
  background: none;
  color: #bbbbbb;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 12px;
  height: auto;
  left: -15px;
  margin: 0;
  position: absolute;
  right: auto;
  top: -1px;
  transform: none;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--active [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--active [type=radio]:not(:checked) + label:before {
  border: 1px solid #82aa3b;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item--active label:after {
  -webkit-transform: none;
  background: none;
  color: #82aa3b;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 12px;
  height: auto;
  left: -15px;
  margin: 0;
  right: auto;
  top: -1px;
  transform: none;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.hide-btn-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-active-tab span {
  color: #fff !important;
}
.ct-ultimate-gdpr-cookie-modal-compact-green [type=radio]:checked + label:before, .ct-ultimate-gdpr-cookie-modal-compact-green [type=radio]:not(:checked) + label:before {
  background-color: transparent;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 11px;
  left: -17px;
  margin: 0;
  top: 1px;
  width: 11px;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .hide-btn {
  background-color: transparent;
  border-radius: 0;
  color: #595959;
  display: inline-block;
  font-family: open_sans_regular, sans-serif;
  font-size: 10px;
  font-weight: 400;
  height: auto;
  letter-spacing: normal;
  padding: 12px 10px;
  text-transform: none;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .fa {
  font-size: 8px;
  margin-left: 3px;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-btn.compact {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-wrap {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0;
  height: auto;
  overflow: visible;
  padding: 0 0 15px 0;
  position: relative;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .cookie-modal-tab-wrapper {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .cookie-modal-tab-wrapper li {
  background-color: #f2f2f2;
  cursor: pointer;
  display: table;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0 4px 0 15px;
  text-align: left;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .cookie-modal-tab-wrapper li:not(:first-child) {
  border-top: 1px solid #fff;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .cookie-modal-tab-wrapper li:last-child {
  border-bottom-left-radius: 5px;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .cookie-modal-tab-wrapper li span {
  display: table-cell;
  height: 26px;
  padding: 0;
  vertical-align: middle;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .cookie-modal-tab-wrapper li.ct-ultimate-gdpr-cookie-modal-active-tab {
  background-color: #82aa3b;
  color: #fff;
  font-weight: 400;
  padding: 0 4px 0 15px;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap {
  display: block !important;
  height: auto;
  margin: 0;
  overflow-y: scroll;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-info {
  height: 100%;
  padding: 0 20px;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-desc:first-child h4 {
  color: #82aa3b;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-desc:nth-child(2) h4 {
  color: #f64d40;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-desc li:not(:first-child) {
  margin: 7px 0 0 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-desc li:before {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green h4 {
  font-family: open_sans_regular, sans-serif;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
  margin: 6px 0 8px 0;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-able,
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-not-able {
  margin: 0;
  padding-left: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-able li,
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-not-able li {
  color: inherit;
  font-family: open_sans_regular, sans-serif;
  font-size: 9px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-btn {
  display: block;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-modal-compact-green a {
  background: transparent;
  border-radius: 0;
  border: 0;
  display: block;
  font-size: 0;
  padding: 0;
  width: auto;
}
.ct-ultimate-gdpr-cookie-modal-compact-green a:after {
  -webkit-box-sizing: border-box;
  background-color: #82aa3b;
  border: 1px solid #82aa3b;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  content: "OK";
  display: inline-block;
  font-family: open_sans_regular, sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: normal;
  padding: 6px 25px;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-compact-green a:hover {
  border: none !important;
}
.ct-ultimate-gdpr-cookie-modal-compact-green a:hover:after {
  background-color: transparent;
  border: 1px solid #82aa3b;
  color: #82aa3b;
}

@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-content {
    overflow: visible;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-desc {
    margin: 0;
    max-width: 415px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue li.ct-ultimate-gdpr-cookie-modal-slider-item {
    margin: 0;
    padding: 8px 15px 11px 30px;
    text-align: center;
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider .hide-btn-wrapper {
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .hide-btn {
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue #ct-ultimate-gdpr-cookie-modal-slider-form .ct-ultimate-gdpr-cookie-modal-slider .ct-ultimate-gdpr-cookie-modal-btn.compact {
    display: inline;
    float: right;
    padding: 0;
    position: static;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue a {
    display: inline-block;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue a:after {
    padding: 9px 25px 8px 25px;
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue a:hover:after {
    background-color: transparent;
    color: #4265b8;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-wrap {
    border: 1px solid #e1e1e1;
    height: 136px;
    padding: 0;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .cookie-modal-tab-wrapper {
    color: #595959;
    display: block;
    font-family: Hind, sans-serif;
    font-size: 11px;
    font-weight: 600;
    left: 0;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    position: absolute;
    text-transform: none;
    top: 0;
    width: 99px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap {
    height: 134px;
    margin-left: 100px;
    overflow-y: scroll;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-slider-desc li:not(:first-child) {
    margin-top: 8px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-dark-blue .ct-ultimate-gdpr-cookie-modal-btn {
    display: none;
    position: absolute;
    right: 0;
    top: 180px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-content {
    overflow: visible;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue li.ct-ultimate-gdpr-cookie-modal-slider-item {
    margin: 0;
    padding: 8px 15px 11px 30px;
    text-align: center;
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider .hide-btn-wrapper {
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue .hide-btn {
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue #ct-ultimate-gdpr-cookie-modal-slider-form .ct-ultimate-gdpr-cookie-modal-slider .ct-ultimate-gdpr-cookie-modal-btn.compact {
    display: inline;
    float: right;
    padding: 0;
    position: static;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue a {
    display: inline-block;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue a:before {
    color: #fff;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 8px;
    left: 14px;
    position: absolute;
    top: 14px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue a:hover:before {
    color: #6a8ee7;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 8px;
    left: 14px;
    position: absolute;
    top: 14px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue a:hover:after {
    background-color: transparent;
    color: #4265b8;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue a:after {
    padding: 10px 25px;
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-wrap {
    border: 1px solid #e1e1e1;
    height: 136px;
    padding: 0;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue .cookie-modal-tab-wrapper {
    color: #595959;
    display: block;
    font-size: 10px;
    font-family: open_sans_regular, sans-serif;
    font-weight: 400;
    left: 0;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    position: absolute;
    text-transform: none;
    top: 0;
    width: 99px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap {
    height: 134px;
    margin-left: 100px;
    overflow-y: scroll;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-light-blue .ct-ultimate-gdpr-cookie-modal-btn {
    display: none;
    position: absolute;
    right: 0;
    top: 180px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
    height: 40px;
    width: 14%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
    height: 40px;
    width: 27%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
    height: 40px;
    width: 41%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-1 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-2 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-3 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--NoBlockGroup.ct-ultimate-gdpr--Groups-4 .ct-ultimate-gdpr-slider-not-block .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
    height: 40px;
    width: 54%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie1 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie1 .ct-ultimate-gdpr-slider {
    width: 14%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie2 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie2 .ct-ultimate-gdpr-slider {
    height: 40px;
    margin: 0 0 0 13%;
    width: 14%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie3 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie3 .ct-ultimate-gdpr-slider {
    height: 40px;
    margin: 0 0 0 13%;
    width: 28%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie4 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie4 .ct-ultimate-gdpr-slider {
    height: 40px;
    margin: 0 0 0 13%;
    width: 41%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-2 .ct-slider-cookie5 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-3 .ct-slider-cookie5 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-4 .ct-slider-cookie5 .ct-ultimate-gdpr-slider, .ct-ultimate-gdpr-cookie-modal-compact-green.ct-ultimate-gdpr--Groups-5 .ct-slider-cookie5 .ct-ultimate-gdpr-slider {
    height: 40px;
    margin: 0 0 0 13%;
    width: 55%;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-content {
    overflow: visible;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green li.ct-ultimate-gdpr-cookie-modal-slider-item {
    margin: 0;
    padding: 8px 15px 11px 30px;
    text-align: center;
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green li.hide-btn-wrapper {
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green .hide-btn {
    width: auto;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green #ct-ultimate-gdpr-cookie-modal-slider-form .ct-ultimate-gdpr-cookie-modal-slider .ct-ultimate-gdpr-cookie-modal-btn.compact {
    display: inline;
    float: right;
    padding: 0;
    position: static;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green a {
    display: inline-block;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green a:after {
    padding: 8px 25px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green a:hover:after {
    -webkit-transition: all 0.3s ease-in-out;
    background-color: transparent;
    color: #82aa3b;
    transition: all 0.3s ease-in-out;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-wrap {
    border: 1px solid #e1e1e1;
    height: 136px;
    padding: 0;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green .cookie-modal-tab-wrapper {
    color: #595959;
    display: block;
    font-family: Hind, sans-serif;
    font-size: 11px;
    font-weight: 600;
    left: 0;
    letter-spacing: normal;
    margin: 0;
    padding: 0;
    position: absolute;
    text-transform: none;
    top: 0;
    width: 99px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-slider-inner-wrap {
    height: 134px;
    margin-left: 100px;
    overflow-y: scroll;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green .ct-ultimate-gdpr-cookie-modal-btn {
    display: none;
    position: absolute;
    right: 0;
    top: 180px;
  }
  .ct-ultimate-gdpr-cookie-modal-compact-green a:after {
    width: auto;
  }
}
/* END COOKIE MODAL COMPACT */
.ct-ultimate-gdpr-button-icon-left {
  padding-right: 8px;
}

.ct-ultimate-gdpr-button-icon-right {
  padding-left: 8px;
}

#ct-ultimate-gdpr-cookie-popup {
  box-sizing: border-box;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic {
  width: 540px;
  padding: 30px;
  background-color: #ff7d27;
  color: white;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic .ct-container {
  width: 100%;
}
@media (min-width: 1200px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic .ct-container {
    width: 1170px;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct_ultimate-gdpr-cookie-reject {
  float: left;
  border: 1px solid;
  margin-top: 20px;
  margin-left: 15px;
  text-align: center;
  padding: 6px 7px;
  color: #fff;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct_ultimate-gdpr-cookie-reject {
    float: none;
    margin: 5px 0;
    width: 100%;
    padding: 6px 0;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-content h1,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-content h2,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-content h3,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-content h4,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-content h5,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-content h6 {
  color: #fff;
  font-family: montserrat_regular, sans-serif;
  font-size: 20px;
  margin: 15px 0;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-read-more {
  margin: 20px 0px !important;
  color: white;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
  color: white;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light {
  width: 570px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-read-more,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-change-settings, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-read-more,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-change-settings {
  font-family: montserrat_semibold, sans-serif;
  font-size: 12px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct_ultimate-gdpr-cookie-reject, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct_ultimate-gdpr-cookie-reject {
  margin-top: 20px;
  margin-left: 7px;
  padding: 8px;
  background: #6a8ee7;
  border-color: #6a8ee7;
  color: #fff;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-accept, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-accept {
  background: #6a8ee7;
  border-color: #6a8ee7;
  color: #fff;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-read-more, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-read-more {
  background: #808080;
  border-color: #808080;
  color: #fff;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-change-settings, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-change-settings {
  background: transparent;
  border-color: #808080;
  color: #808080;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-read-more,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-change-settings, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-read-more,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-change-settings {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 9px 15px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-accept:hover,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-read-more:hover,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-change-settings:hover, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-accept:hover,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-read-more:hover,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-change-settings:hover {
  background-color: #0948e4;
  border-color: #0948e4;
  color: #fff;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue .ct-ultimate-gdpr-cookie-popup-bottomPanel,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue .ct-ultimate-gdpr-cookie-popup-topPanel, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light .ct-ultimate-gdpr-cookie-popup-bottomPanel,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light .ct-ultimate-gdpr-cookie-popup-topPanel {
  max-width: 1170px;
  padding: 0 !important;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue .ct-container {
  width: 100%;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-content {
  color: #ccc;
  margin: 0 0 30px 0;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-content h1,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-content h2,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-content h3,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-content h4,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-content h5,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-content h6 {
  color: #fff;
  font-family: montserrat_bold, sans-serif;
  font-size: 20px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-read-more,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-change-settings {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #fff;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-change-settings {
  border-color: #999999;
  color: #999;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light {
  padding: 50px 30px 30px 30px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light img {
  vertical-align: baseline;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light .ct-container {
  width: 100%;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-content {
  color: #808080;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-content h1,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-content h2,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-content h3,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-content h4,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-content h5,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-content h6 {
  color: #333333;
  font-family: montserrat_bold, sans-serif;
  margin-top: 0;
  padding-top: 10px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-read-more,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-change-settings {
  font-family: montserrat_bold, sans-serif;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-read-more {
  background-color: #262626;
  border-color: #262626;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-change-settings {
  border-color: #cccccc;
  color: #b2b2b2;
  padding: 9px 15px;
}
@media screen and (max-width: 500px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic {
    max-width: 500px;
    width: auto !important;
    left: 0 !important;
    right: 0 !important;
  }
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-read-more,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-change-settings {
    text-align: center;
    float: none;
    margin: 20px 0 !important;
  }
}

/* PRIVACY CENTER */
.detailed-features {
  padding: 0 15px !important;
  width: 100%;
}
.detailed-features .feature {
  background-color: #f5f5f5;
  float: left;
  padding: 20px;
  position: relative;
  width: 100%;
  text-align: center;
}
.detailed-features .feature:nth-child(odd), .detailed-features .feature:nth-child(even) {
  margin: 0 0 15px 0;
}
.detailed-features .feature:hover .ct-btn {
  background-color: #000;
}
.detailed-features .feature p {
  display: none;
}
.detailed-features .feature a {
  color: #fff;
  font-family: dosis_regular, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  border: none;
  box-shadow: none;
}
.detailed-features .feature .fa {
  width: 80px;
  background-color: #fff;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  color: #ff7e27;
  font-size: 27px;
}
.detailed-features .feature .section {
  display: block;
  padding: 0;
  width: auto;
}
.detailed-features .feature .ct-full-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.detailed-features .feature .text {
  font-family: open_sans_regular, sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 17px;
  text-transform: capitalize;
  padding: 0;
  line-height: normal;
  vertical-align: top;
  margin: 30px 0 0 0;
}
.detailed-features .feature .ct-btn {
  background-color: #1a1a1a;
  border-radius: 30px;
  margin: 22px 0 0 0;
  vertical-align: top;
  padding: 8px 20px;
  line-height: normal;
}
.detailed-features .feature .ct-btn .fa {
  width: auto;
  height: auto;
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  line-height: normal;
  margin: 0 0 0 10px;
}
.detailed-features.ct-tablet, .detailed-features.ct-desktop {
  max-width: 1170px;
}
.detailed-features.ct-tablet .feature, .detailed-features.ct-desktop .feature {
  text-align: left;
}
.detailed-features.ct-tablet .feature .section, .detailed-features.ct-desktop .feature .section {
  display: inline-block;
}
.detailed-features.ct-tablet .feature .text, .detailed-features.ct-desktop .feature .text {
  padding: 0 0 0 10px;
}
.detailed-features.ct-tablet .feature .ct-btn, .detailed-features.ct-desktop .feature .ct-btn {
  float: right;
}
.detailed-features.ct-desktop .feature {
  width: 45% !important;
}
.detailed-features.ct-desktop .feature:nth-child(odd) {
  margin: 0 15px 30px 0;
}
.detailed-features.ct-desktop .feature:nth-child(even) {
  margin: 0 0 30px 15px;
}

/* END PRIVACY CENTER */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.ct-ultimate-gdpr-table-responsive {
  overflow-x: auto;
}
.ct-ultimate-gdpr-table-responsive * {
  vertical-align: top;
}
.ct-ultimate-gdpr-table-responsive table {
  width: auto;
}
.ct-ultimate-gdpr-table-responsive th,
.ct-ultimate-gdpr-table-responsive td {
  padding: 5px;
}

/* COOKIE POPUP */
#ct-ultimate-gdpr-cookie-popup {
  border-radius: 5px;
}
#ct-ultimate-gdpr-cookie-popup .fa {
  font-family: FontAwesome;
  font-size: 14px;
}

.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings {
  border-radius: 5px;
}

.ct-ultimate-gdpr-cookie-popup-10-set #ct_ultimate-gdpr-cookie-reject {
  display: inline-block;
  float: none;
  padding: 10px 11px;
  margin-left: 8px;
}
.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}
.ct-ultimate-gdpr-cookie-popup-10-set h1,
.ct-ultimate-gdpr-cookie-popup-10-set h2,
.ct-ultimate-gdpr-cookie-popup-10-set h3,
.ct-ultimate-gdpr-cookie-popup-10-set h4,
.ct-ultimate-gdpr-cookie-popup-10-set h5,
.ct-ultimate-gdpr-cookie-popup-10-set h6 {
  font-family: montserrat_medium, sans-serif;
  font-size: 18px;
  margin: 0 0 10px 0;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  clear: both;
  content: "";
  display: table;
  margin: -20px auto -35px auto;
  max-width: 1170px;
}
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
  float: none;
  text-align: left;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
  .ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
    float: left;
    width: 60%;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel h1,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel h2,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel h3,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel h4,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel h5,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel h6,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel h1,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel h2,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel h3,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel h4,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel h5,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel h6 {
  text-align: left;
}
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper,
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  float: none;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper,
  .ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    float: right;
    text-align: right;
    width: 40%;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-change-settings {
  font-family: montserrat_semibold, sans-serif;
  text-align: center;
  vertical-align: top;
}
.ct-ultimate-gdpr-cookie-popup-10-set .ct-ultimate-gdpr-cookie-popup-close {
  display: none;
}
.ct-ultimate-gdpr-cookie-popup-10-set .fa {
  font-weight: normal;
}

/* APAS */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-apas {
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 35px 40px;
  text-align: center;
  width: 527px;
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-apas {
    left: 0 !important;
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-apas #ct_ultimate-gdpr-cookie-reject {
  margin-top: 0;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-apas #ct_ultimate-gdpr-cookie-reject {
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
  }
}

.ct-ultimate-gdpr-cookie-popup-apas.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-apas.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-apas.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings {
  border-radius: 30px;
}
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    width: 48%;
  }
}
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
  margin: 10px 0 0 0;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin: 0 0 0 10px;
  }
}
.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-content span {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-content .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-apas h1,
.ct-ultimate-gdpr-cookie-popup-apas h2,
.ct-ultimate-gdpr-cookie-popup-apas h3,
.ct-ultimate-gdpr-cookie-popup-apas h4,
.ct-ultimate-gdpr-cookie-popup-apas h5,
.ct-ultimate-gdpr-cookie-popup-apas h6 {
  margin: 10px 0 15px 0;
  text-align: center;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-apas .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  padding: 25px 0 0 0;
}
.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-change-settings {
    width: auto;
  }
}
.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}
.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-change-settings {
  margin: 10px 0 0 0;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-change-settings {
    margin: 0;
  }
}

/* BLUE */
.ct-ultimate-gdpr-cookie-popup-apas-blue h1,
.ct-ultimate-gdpr-cookie-popup-apas-blue h2,
.ct-ultimate-gdpr-cookie-popup-apas-blue h3,
.ct-ultimate-gdpr-cookie-popup-apas-blue h4,
.ct-ultimate-gdpr-cookie-popup-apas-blue h5,
.ct-ultimate-gdpr-cookie-popup-apas-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-apas-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-apas-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-apas-blue #ct-ultimate-gdpr-cookie-change-settings {
  margin-left: 0;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-apas-blue #ct-ultimate-gdpr-cookie-change-settings {
    margin-left: 8px;
  }
}

/* END BLUE */
/* BLACK */
.ct-ultimate-gdpr-cookie-popup-apas-black h1,
.ct-ultimate-gdpr-cookie-popup-apas-black h2,
.ct-ultimate-gdpr-cookie-popup-apas-black h3,
.ct-ultimate-gdpr-cookie-popup-apas-black h4,
.ct-ultimate-gdpr-cookie-popup-apas-black h5,
.ct-ultimate-gdpr-cookie-popup-apas-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-apas-black #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-apas-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-apas-black #ct-ultimate-gdpr-cookie-change-settings {
  margin-left: 0;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-apas-black #ct-ultimate-gdpr-cookie-change-settings {
    margin-left: 8px;
  }
}

/* END BLACK */
/* WHITE */
.ct-ultimate-gdpr-cookie-popup-apas-white h1,
.ct-ultimate-gdpr-cookie-popup-apas-white h2,
.ct-ultimate-gdpr-cookie-popup-apas-white h3,
.ct-ultimate-gdpr-cookie-popup-apas-white h4,
.ct-ultimate-gdpr-cookie-popup-apas-white h5,
.ct-ultimate-gdpr-cookie-popup-apas-white h6 {
  color: #333333;
}
.ct-ultimate-gdpr-cookie-popup-apas-white #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-apas-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-apas-white #ct-ultimate-gdpr-cookie-change-settings {
  margin-left: 0;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-apas-white #ct-ultimate-gdpr-cookie-change-settings {
    margin-left: 8px;
  }
}

/* END WHITE */
/* END APAS */
/* KAHK */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk {
  box-shadow: none;
  box-sizing: border-box;
  padding: 35px;
  text-align: center;
  width: 580px;
}
@media screen and (min-width: 568px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk {
    padding: 35px 60px;
  }
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk {
    left: 0 !important;
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings {
  width: auto;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings {
    width: 55%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 25px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk #ct_ultimate-gdpr-cookie-reject {
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 11px 15px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kahk #ct_ultimate-gdpr-cookie-reject {
    margin-left: 0;
    margin-right: 0;
  }
}

.ct-ultimate-gdpr-cookie-popup-kahk.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-kahk.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-kahk.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings {
  border-radius: 30px;
}
.ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  margin: -20px auto -35px auto;
  width: 100%;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    width: 45%;
  }
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept {
    margin: 10px 0 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept {
    margin: 15px 0 0 0;
  }
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin: 10px 0 0 10px;
  }
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-kahk .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin: 15px 0 0 10px;
  }
}
.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto 0 auto;
  text-align: center;
}
.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-content span .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-kahk h1,
.ct-ultimate-gdpr-cookie-popup-kahk h2,
.ct-ultimate-gdpr-cookie-popup-kahk h3,
.ct-ultimate-gdpr-cookie-popup-kahk h4,
.ct-ultimate-gdpr-cookie-popup-kahk h5,
.ct-ultimate-gdpr-cookie-popup-kahk h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 10px 0 15px 0;
  text-align: center;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  margin: 25px 0 0 0;
  min-width: 30%;
  padding: 11px 15px;
  width: 70%;
}
.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}

.ct-ultimate-gdpr-cookie-popup-kahk-blue h1,
.ct-ultimate-gdpr-cookie-popup-kahk-blue h2,
.ct-ultimate-gdpr-cookie-popup-kahk-blue h3,
.ct-ultimate-gdpr-cookie-popup-kahk-blue h4,
.ct-ultimate-gdpr-cookie-popup-kahk-blue h5,
.ct-ultimate-gdpr-cookie-popup-kahk-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-kahk-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #f4f4f4;
}
.ct-ultimate-gdpr-cookie-popup-kahk-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-kahk-blue #ct-ultimate-gdpr-cookie-change-settings {
  margin: 10px 0 0 0;
}

.ct-ultimate-gdpr-cookie-popup-kahk-black h1,
.ct-ultimate-gdpr-cookie-popup-kahk-black h2,
.ct-ultimate-gdpr-cookie-popup-kahk-black h3,
.ct-ultimate-gdpr-cookie-popup-kahk-black h4,
.ct-ultimate-gdpr-cookie-popup-kahk-black h5,
.ct-ultimate-gdpr-cookie-popup-kahk-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-kahk-black #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-kahk-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-kahk-black #ct-ultimate-gdpr-cookie-change-settings {
  margin: 10px 0 0 0;
}

.ct-ultimate-gdpr-cookie-popup-kahk-white h1,
.ct-ultimate-gdpr-cookie-popup-kahk-white h2,
.ct-ultimate-gdpr-cookie-popup-kahk-white h3,
.ct-ultimate-gdpr-cookie-popup-kahk-white h4,
.ct-ultimate-gdpr-cookie-popup-kahk-white h5,
.ct-ultimate-gdpr-cookie-popup-kahk-white h6 {
  color: #333333;
}
.ct-ultimate-gdpr-cookie-popup-kahk-white #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-kahk-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-kahk-white #ct-ultimate-gdpr-cookie-change-settings {
  margin: 10px 0 0 0;
}

/* END KAHK */
/* OREO */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-oreo {
  box-shadow: none;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: 560px;
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-oreo {
    left: 0 !important;
    width: 100%;
  }
}

.ct-ultimate-gdpr-cookie-popup-oreo.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-standard-settings #ct-ultimate-gdpr-cookie-accept, .ct-ultimate-gdpr-cookie-popup-oreo.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-standard-settings #ct-ultimate-gdpr-cookie-accept {
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
  margin: 0;
  padding: 30px;
}
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel h1,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel h2,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel h3,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel h4,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel h5,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel h6,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel h1,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel h2,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel h3,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel h4,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel h5,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel h6 {
  text-align: left;
}
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
  padding: 15px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    padding: 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto 44px auto;
  padding: 35px 40px 0 40px;
  text-align: center;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-oreo h1,
.ct-ultimate-gdpr-cookie-popup-oreo h2,
.ct-ultimate-gdpr-cookie-popup-oreo h3,
.ct-ultimate-gdpr-cookie-popup-oreo h4,
.ct-ultimate-gdpr-cookie-popup-oreo h5,
.ct-ultimate-gdpr-cookie-popup-oreo h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 10px 0 15px 0;
  text-align: center;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  font-family: montserrat_semibold, sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings {
    text-align: center;
    width: 50%;
  }
}
.ct-ultimate-gdpr-cookie-popup-oreo.ct-ultimate-gdpr-cookie-popup-standard-settings #ct-ultimate-gdpr-cookie-accept {
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept {
  float: left;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings {
  width: auto;
  min-width: 132px;
  margin-top: 15px !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject,
  .ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings {
    width: 100%;
    float: none;
  }
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings {
  margin-bottom: 20px;
}
.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject {
  margin-top: 2px;
  text-align: center;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  padding: 0 30px 20px 30px;
}

.ct-ultimate-gdpr-cookie-popup-oreo-blue .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-oreo-blue .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  color: #959fb8;
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: -29px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-oreo-blue .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-oreo-blue h1,
.ct-ultimate-gdpr-cookie-popup-oreo-blue h2,
.ct-ultimate-gdpr-cookie-popup-oreo-blue h3,
.ct-ultimate-gdpr-cookie-popup-oreo-blue h4,
.ct-ultimate-gdpr-cookie-popup-oreo-blue h5,
.ct-ultimate-gdpr-cookie-popup-oreo-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-oreo-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #60befc;
}
.ct-ultimate-gdpr-cookie-popup-oreo-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-oreo-black .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-oreo-black .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  color: #959fb8;
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: -29px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-oreo-black .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-oreo-black h1,
.ct-ultimate-gdpr-cookie-popup-oreo-black h2,
.ct-ultimate-gdpr-cookie-popup-oreo-black h3,
.ct-ultimate-gdpr-cookie-popup-oreo-black h4,
.ct-ultimate-gdpr-cookie-popup-oreo-black h5,
.ct-ultimate-gdpr-cookie-popup-oreo-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-oreo-black #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-oreo-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-oreo-white .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-oreo-white .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  color: #959fb8;
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  right: 10px;
  top: -29px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-oreo-white .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-oreo-white h1,
.ct-ultimate-gdpr-cookie-popup-oreo-white h2,
.ct-ultimate-gdpr-cookie-popup-oreo-white h3,
.ct-ultimate-gdpr-cookie-popup-oreo-white h4,
.ct-ultimate-gdpr-cookie-popup-oreo-white h5,
.ct-ultimate-gdpr-cookie-popup-oreo-white h6 {
  color: #333333;
}
.ct-ultimate-gdpr-cookie-popup-oreo-white #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-oreo-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}

/* END OREO */
/* WAFER */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer {
  border-radius: 15px !important;
  box-shadow: none;
  box-sizing: border-box;
  padding: 35px 40px;
  text-align: center;
  width: 560px;
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer {
    left: 0 !important;
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-change-settings {
  width: auto;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-change-settings {
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject {
  margin-top: 25px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject {
    width: 100%;
    margin-left: 0;
  }
}

.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-popup-squared {
  border-radius: 0 !important;
}
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel, .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  right: 0;
}
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-content {
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel h1,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel h2,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel h3,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel h4,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel h5,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel h6, .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel h1,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel h2,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel h3,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel h4,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel h5,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel h6 {
  margin: 0 0 10px 0;
}
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-change-settings {
  float: none;
  text-align: center;
  vertical-align: top;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-change-settings {
    width: 45%;
  }
}
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-change-settings {
  margin-left: 0;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin: 25px 0 0 8px;
  }
}
.ct-ultimate-gdpr-cookie-popup-wafer .ct-ultimate-gdpr-cookie-popup-bottomPanel,
.ct-ultimate-gdpr-cookie-popup-wafer .ct-ultimate-gdpr-cookie-popup-topPanel {
  max-width: 1055px;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-bottomPanel {
  bottom: 26px !important;
}
.ct-ultimate-gdpr-cookie-popup-wafer.ct-ultimate-gdpr-cookie-topPanel {
  top: 26px !important;
}
.ct-ultimate-gdpr-cookie-popup-wafer .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-wafer .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  color: #5e5e5f;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: -20px;
  top: -20px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-wafer .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-wafer h1,
.ct-ultimate-gdpr-cookie-popup-wafer h2,
.ct-ultimate-gdpr-cookie-popup-wafer h3,
.ct-ultimate-gdpr-cookie-popup-wafer h4,
.ct-ultimate-gdpr-cookie-popup-wafer h5,
.ct-ultimate-gdpr-cookie-popup-wafer h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 10px 0 15px 0;
  text-align: left;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  margin: 25px 0 0 0;
  padding: 11px 15px;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-change-settings {
    width: 48%;
  }
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-change-settings {
    margin-left: 10px;
  }
}
.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}

.ct-ultimate-gdpr-cookie-popup-wafer-blue h1,
.ct-ultimate-gdpr-cookie-popup-wafer-blue h2,
.ct-ultimate-gdpr-cookie-popup-wafer-blue h3,
.ct-ultimate-gdpr-cookie-popup-wafer-blue h4,
.ct-ultimate-gdpr-cookie-popup-wafer-blue h5,
.ct-ultimate-gdpr-cookie-popup-wafer-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-wafer-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-wafer-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-wafer-black h1,
.ct-ultimate-gdpr-cookie-popup-wafer-black h2,
.ct-ultimate-gdpr-cookie-popup-wafer-black h3,
.ct-ultimate-gdpr-cookie-popup-wafer-black h4,
.ct-ultimate-gdpr-cookie-popup-wafer-black h5,
.ct-ultimate-gdpr-cookie-popup-wafer-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-wafer-black #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-wafer-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-wafer-white h1,
.ct-ultimate-gdpr-cookie-popup-wafer-white h2,
.ct-ultimate-gdpr-cookie-popup-wafer-white h3,
.ct-ultimate-gdpr-cookie-popup-wafer-white h4,
.ct-ultimate-gdpr-cookie-popup-wafer-white h5,
.ct-ultimate-gdpr-cookie-popup-wafer-white h6 {
  color: #333333;
}
.ct-ultimate-gdpr-cookie-popup-wafer-white #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-wafer-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}

/* END WAFER */
/* JUMBLE */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble {
  box-sizing: border-box;
  box-shadow: none;
  padding: 35px 40px;
  text-align: center;
  width: 560px;
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble {
    left: 0 !important;
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-change-settings {
  width: auto;
  min-width: 123px;
  float: none;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-change-settings {
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject {
  margin-top: 20px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject {
    width: 100%;
    margin-left: 0;
  }
}

.ct-ultimate-gdpr-cookie-popup-jumble.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-jumble.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-jumble.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings {
  border-radius: 30px;
}
.ct-ultimate-gdpr-cookie-popup-jumble .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-jumble .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  color: #959fb8;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: -20px;
  top: -20px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-jumble .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-jumble .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-jumble .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  margin: -20px auto -35px auto;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-jumble .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-jumble .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-content {
    margin: 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-content * {
  display: inline;
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-content > :first-child:before {
  line-height: normal;
  margin: 0 4px 0 0;
  vertical-align: sub;
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-content span .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-jumble h1,
.ct-ultimate-gdpr-cookie-popup-jumble h2,
.ct-ultimate-gdpr-cookie-popup-jumble h3,
.ct-ultimate-gdpr-cookie-popup-jumble h4,
.ct-ultimate-gdpr-cookie-popup-jumble h5,
.ct-ultimate-gdpr-cookie-popup-jumble h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 0 0 15px 0;
  text-align: left;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-jumble p {
  color: inherit;
  line-height: 24px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  margin: 20px 0 0 0;
  padding: 10px 15px;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-change-settings {
    width: 48%;
  }
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-change-settings {
  margin: 20px 0 0 10px;
}
.ct-ultimate-gdpr-cookie-popup-jumble #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}

#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-jumble p {
  display: block;
}

.ct-ultimate-gdpr-cookie-popup-jumble-blue h1,
.ct-ultimate-gdpr-cookie-popup-jumble-blue h2,
.ct-ultimate-gdpr-cookie-popup-jumble-blue h3,
.ct-ultimate-gdpr-cookie-popup-jumble-blue h4,
.ct-ultimate-gdpr-cookie-popup-jumble-blue h5,
.ct-ultimate-gdpr-cookie-popup-jumble-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-jumble-blue #ct-ultimate-gdpr-cookie-content:before {
  content: url(images/Cookie-pop-up-blue.png);
  line-height: normal;
  margin: 0 4px 0 0;
  vertical-align: sub;
}
.ct-ultimate-gdpr-cookie-popup-jumble-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-jumble-blue #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}
.ct-ultimate-gdpr-cookie-popup-jumble-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-jumble-blue #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  padding: 11px 15px;
}

.ct-ultimate-gdpr-cookie-popup-jumble-black h1,
.ct-ultimate-gdpr-cookie-popup-jumble-black h2,
.ct-ultimate-gdpr-cookie-popup-jumble-black h3,
.ct-ultimate-gdpr-cookie-popup-jumble-black h4,
.ct-ultimate-gdpr-cookie-popup-jumble-black h5,
.ct-ultimate-gdpr-cookie-popup-jumble-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-jumble-black #ct-ultimate-gdpr-cookie-content:before {
  content: url(images/Cookie-pop-up-black.png);
  line-height: normal;
  margin: 0 4px 0 0;
  vertical-align: sub;
}
.ct-ultimate-gdpr-cookie-popup-jumble-black #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-jumble-black #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}
.ct-ultimate-gdpr-cookie-popup-jumble-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-jumble-black #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  padding: 10px 15px;
}

.ct-ultimate-gdpr-cookie-popup-jumble-white h1,
.ct-ultimate-gdpr-cookie-popup-jumble-white h2,
.ct-ultimate-gdpr-cookie-popup-jumble-white h3,
.ct-ultimate-gdpr-cookie-popup-jumble-white h4,
.ct-ultimate-gdpr-cookie-popup-jumble-white h5,
.ct-ultimate-gdpr-cookie-popup-jumble-white h6 {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-jumble-white #ct-ultimate-gdpr-cookie-content:before {
  content: url(images/Cookie-pop-up-white.png);
  line-height: normal;
  margin: 0 4px 0 0;
  vertical-align: sub;
}
.ct-ultimate-gdpr-cookie-popup-jumble-white #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-jumble-white #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}
.ct-ultimate-gdpr-cookie-popup-jumble-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-jumble-white #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  padding: 11px 15px;
}

/* END JUMBLE */
/* KHAPSE */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse {
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 35px 40px;
  text-align: center;
  width: 560px;
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse {
    left: 0 !important;
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-change-settings {
  width: auto;
  float: none !important;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-change-settings {
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject {
  margin-top: 20px;
  padding: 12px 11px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject {
    width: 100%;
    margin-left: 0;
  }
}

.ct-ultimate-gdpr-cookie-popup-khapse.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-khapse.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-khapse.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings {
  border-radius: 5px;
}
.ct-ultimate-gdpr-cookie-popup-khapse .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-khapse .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  color: #959fb8;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: -20px;
  top: -15px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-khapse .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-khapse .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-khapse .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  margin: -20px auto -35px auto;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  text-align: center;
}
.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-content span .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-khapse .ct-ultimate-gdpr-cookie-content-cookie-image-wrapper {
  float: left;
  margin: 0 7px 0 0;
}
.ct-ultimate-gdpr-cookie-popup-khapse h1,
.ct-ultimate-gdpr-cookie-popup-khapse h2,
.ct-ultimate-gdpr-cookie-popup-khapse h3,
.ct-ultimate-gdpr-cookie-popup-khapse h4,
.ct-ultimate-gdpr-cookie-popup-khapse h5,
.ct-ultimate-gdpr-cookie-popup-khapse h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 0 0 15px 0;
  text-align: center;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  margin: 20px 0 0 0;
  padding: 12px 15px;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-change-settings {
    width: 48%;
  }
}
.ct-ultimate-gdpr-cookie-popup-khapse #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}

.ct-ultimate-gdpr-cookie-popup-khapse-blue h1,
.ct-ultimate-gdpr-cookie-popup-khapse-blue h2,
.ct-ultimate-gdpr-cookie-popup-khapse-blue h3,
.ct-ultimate-gdpr-cookie-popup-khapse-blue h4,
.ct-ultimate-gdpr-cookie-popup-khapse-blue h5,
.ct-ultimate-gdpr-cookie-popup-khapse-blue h6 {
  color: #cccccc;
}
.ct-ultimate-gdpr-cookie-popup-khapse-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #de7834;
}
.ct-ultimate-gdpr-cookie-popup-khapse-blue #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}
.ct-ultimate-gdpr-cookie-popup-khapse-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-khapse-blue #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  margin-left: 10px;
}

.ct-ultimate-gdpr-cookie-popup-khapse-black h1,
.ct-ultimate-gdpr-cookie-popup-khapse-black h2,
.ct-ultimate-gdpr-cookie-popup-khapse-black h3,
.ct-ultimate-gdpr-cookie-popup-khapse-black h4,
.ct-ultimate-gdpr-cookie-popup-khapse-black h5,
.ct-ultimate-gdpr-cookie-popup-khapse-black h6 {
  color: #c4c4c4;
}
.ct-ultimate-gdpr-cookie-popup-khapse-black #ct-ultimate-gdpr-cookie-read-more {
  color: #de7834;
}
.ct-ultimate-gdpr-cookie-popup-khapse-black #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}
.ct-ultimate-gdpr-cookie-popup-khapse-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-khapse-black #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  margin-left: 10px;
}

.ct-ultimate-gdpr-cookie-popup-khapse-white h1,
.ct-ultimate-gdpr-cookie-popup-khapse-white h2,
.ct-ultimate-gdpr-cookie-popup-khapse-white h3,
.ct-ultimate-gdpr-cookie-popup-khapse-white h4,
.ct-ultimate-gdpr-cookie-popup-khapse-white h5,
.ct-ultimate-gdpr-cookie-popup-khapse-white h6 {
  color: #333333;
}
.ct-ultimate-gdpr-cookie-popup-khapse-white #ct-ultimate-gdpr-cookie-read-more {
  color: #de7834;
}
.ct-ultimate-gdpr-cookie-popup-khapse-white #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}
.ct-ultimate-gdpr-cookie-popup-khapse-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-khapse-white #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  margin-left: 10px;
}

/* END KHAPSE */
/* TARECO */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco {
  box-shadow: none;
  box-sizing: border-box;
  padding: 35px 40px;
  text-align: center;
  width: 560px;
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco {
    left: 0 !important;
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-change-settings {
  width: auto;
  float: none !important;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-change-settings {
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject {
  margin-top: 20px;
  padding: 12px 11px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject {
    width: 100%;
    margin-left: 0;
  }
}

.ct-ultimate-gdpr-cookie-popup-tareco.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-tareco.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-tareco.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings {
  border-radius: 5px;
}
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-close.ct-ultimate-gdpr-cookie-popup-close-bottom .dashicons-no-alt {
  top: auto;
  bottom: -46px;
}
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-close .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 35px;
  height: auto;
  padding: 7px 4px 3px 4px;
  position: absolute;
  right: -30px;
  top: -45px;
  transition: all 0.3s ease-in-out;
  width: auto;
}
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-close .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    float: none;
    max-width: 45%;
  }
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin: 20px 0 0 10px;
  }
}
.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}
.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-content span .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-tareco .ct-ultimate-gdpr-cookie-content-cookie-image-wrapper {
  float: left;
  margin: 0 7px 0 0;
}
.ct-ultimate-gdpr-cookie-popup-tareco h1,
.ct-ultimate-gdpr-cookie-popup-tareco h2,
.ct-ultimate-gdpr-cookie-popup-tareco h3,
.ct-ultimate-gdpr-cookie-popup-tareco h4,
.ct-ultimate-gdpr-cookie-popup-tareco h5,
.ct-ultimate-gdpr-cookie-popup-tareco h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 10px 0 15px 0;
  text-align: left;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  margin: 20px 0 0 0;
  padding: 12px 15px;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-change-settings {
    width: 48%;
  }
}
.ct-ultimate-gdpr-cookie-popup-tareco #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}

.ct-ultimate-gdpr-cookie-popup-tareco-blue .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-tareco-blue .ct-ultimate-gdpr-cookie-popup-close .dashicons-no-alt {
  background-color: #f7f7f7;
  color: #316ab1;
}
.ct-ultimate-gdpr-cookie-popup-tareco-blue h1,
.ct-ultimate-gdpr-cookie-popup-tareco-blue h2,
.ct-ultimate-gdpr-cookie-popup-tareco-blue h3,
.ct-ultimate-gdpr-cookie-popup-tareco-blue h4,
.ct-ultimate-gdpr-cookie-popup-tareco-blue h5,
.ct-ultimate-gdpr-cookie-popup-tareco-blue h6 {
  color: #234a7c;
}
.ct-ultimate-gdpr-cookie-popup-tareco-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #316ab1;
}
.ct-ultimate-gdpr-cookie-popup-tareco-blue #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}
.ct-ultimate-gdpr-cookie-popup-tareco-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-tareco-blue #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  margin-left: 10px;
}

.ct-ultimate-gdpr-cookie-popup-tareco-black .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-tareco-black .ct-ultimate-gdpr-cookie-popup-close .dashicons-no-alt {
  background-color: #f7f7f7;
  color: #3a3d47;
}
.ct-ultimate-gdpr-cookie-popup-tareco-black h1,
.ct-ultimate-gdpr-cookie-popup-tareco-black h2,
.ct-ultimate-gdpr-cookie-popup-tareco-black h3,
.ct-ultimate-gdpr-cookie-popup-tareco-black h4,
.ct-ultimate-gdpr-cookie-popup-tareco-black h5,
.ct-ultimate-gdpr-cookie-popup-tareco-black h6 {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-tareco-black #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-tareco-black #ct-ultimate-gdpr-cookie-accept {
  border-color: transparent;
}

.ct-ultimate-gdpr-cookie-popup-tareco-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-tareco-black #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  margin-left: 10px;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white .ct-ultimate-gdpr-cookie-popup-close .dashicons-no-alt {
  background-color: #f7f7f7;
  color: #316ab1;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white h1,
.ct-ultimate-gdpr-cookie-popup-tareco-white h2,
.ct-ultimate-gdpr-cookie-popup-tareco-white h3,
.ct-ultimate-gdpr-cookie-popup-tareco-white h4,
.ct-ultimate-gdpr-cookie-popup-tareco-white h5,
.ct-ultimate-gdpr-cookie-popup-tareco-white h6 {
  color: #000;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white #ct-ultimate-gdpr-cookie-read-more {
  color: #316ab1;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  margin-left: 10px;
}

/* END TARECO */
/* KICHEL */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel {
  box-shadow: none;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
}
@media screen and (max-width: 735px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel {
    left: 0 !important;
    width: 100%;
  }
}
@media screen and (min-width: 736px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel {
    width: 676px;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content {
  width: 45%;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content {
    width: 80%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  float: left;
  width: 48%;
  margin: 14px 0;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    width: 100%;
    float: none;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel #ct_ultimate-gdpr-cookie-reject,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
  width: auto;
  float: none;
  margin: 5px 0;
  padding: 12px 15px;
}

.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-close {
  -webkit-transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-family: montserrat_regular, sans-serif;
  line-height: normal;
  padding: 8px 0 0 12px;
  text-align: left;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-close:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-kichel .dashicons-no-alt {
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  height: auto;
  margin: 0 4px 0 0;
  padding: 0;
  vertical-align: baseline;
  width: auto;
}
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
  margin: 0;
  padding: 30px 30px 0 30px;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
    float: left;
    padding: 30px;
    width: 60%;
  }
}
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper,
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  padding: 0 30px;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper,
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    float: right;
    padding: 40px 30px 30px 0;
    width: 40%;
  }
}
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
  width: 100%;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin: 0;
  }
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin: 10px 0 0 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content {
  float: none;
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 25px auto 0 auto;
  text-align: left;
  width: 80%;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content {
    float: left;
    margin: 38px 0 35px 40px;
    width: 330px;
  }
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content span .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: block;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-kichel h1,
.ct-ultimate-gdpr-cookie-popup-kichel h2,
.ct-ultimate-gdpr-cookie-popup-kichel h3,
.ct-ultimate-gdpr-cookie-popup-kichel h4,
.ct-ultimate-gdpr-cookie-popup-kichel h5,
.ct-ultimate-gdpr-cookie-popup-kichel h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 10px 0 15px 0;
  text-align: left;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  font-family: montserrat_semibold, sans-serif;
  font-size: 12px;
  margin: 0 auto;
  padding: 12px 15px;
  width: 80%;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
    float: right;
    margin: 0 40px 0 0;
    width: 235px;
  }
}
.ct-ultimate-gdpr-cookie-popup-kichel.ct-ultimate-gdpr-cookie-popup-standard-settings #ct-ultimate-gdpr-cookie-accept {
  margin-bottom: 30px;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel.ct-ultimate-gdpr-cookie-popup-standard-settings #ct-ultimate-gdpr-cookie-accept {
    margin-top: 80px;
  }
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel.ct-ultimate-gdpr-cookie-popup-standard-settings .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-kichel.ct-ultimate-gdpr-cookie-popup-standard-settings .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept {
    margin-top: 30px;
  }
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-accept {
  margin-top: 50px;
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
  float: none;
  margin: 10px 0 35px 0;
}
@media screen and (min-width: 736px) {
  .ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
    float: right;
    margin: 10px 40px 35px 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings .ct-ultimate-gdpr-cookie-popup-icon-left {
  padding: 0 13px 0 0;
}

.ct-ultimate-gdpr-cookie-popup-kichel-blue .ct-ultimate-gdpr-cookie-popup-close {
  color: #cacaca;
}
.ct-ultimate-gdpr-cookie-popup-kichel-blue .dashicons {
  background-color: #cacaca;
  color: #2a3e71;
}
.ct-ultimate-gdpr-cookie-popup-kichel-blue h1,
.ct-ultimate-gdpr-cookie-popup-kichel-blue h2,
.ct-ultimate-gdpr-cookie-popup-kichel-blue h3,
.ct-ultimate-gdpr-cookie-popup-kichel-blue h4,
.ct-ultimate-gdpr-cookie-popup-kichel-blue h5,
.ct-ultimate-gdpr-cookie-popup-kichel-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-kichel-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-kichel-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-kichel-black .ct-ultimate-gdpr-cookie-popup-close {
  color: #cacaca;
}
.ct-ultimate-gdpr-cookie-popup-kichel-black .dashicons {
  background-color: #cacaca;
  color: #333742;
}
.ct-ultimate-gdpr-cookie-popup-kichel-black h1,
.ct-ultimate-gdpr-cookie-popup-kichel-black h2,
.ct-ultimate-gdpr-cookie-popup-kichel-black h3,
.ct-ultimate-gdpr-cookie-popup-kichel-black h4,
.ct-ultimate-gdpr-cookie-popup-kichel-black h5,
.ct-ultimate-gdpr-cookie-popup-kichel-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-kichel-black #ct-ultimate-gdpr-cookie-read-more {
  color: #51971e;
}
.ct-ultimate-gdpr-cookie-popup-kichel-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-kichel-white .ct-ultimate-gdpr-cookie-popup-close {
  color: grey;
}
.ct-ultimate-gdpr-cookie-popup-kichel-white .dashicons {
  background-color: grey;
  color: #f2f2f2;
}
.ct-ultimate-gdpr-cookie-popup-kichel-white h1,
.ct-ultimate-gdpr-cookie-popup-kichel-white h2,
.ct-ultimate-gdpr-cookie-popup-kichel-white h3,
.ct-ultimate-gdpr-cookie-popup-kichel-white h4,
.ct-ultimate-gdpr-cookie-popup-kichel-white h5,
.ct-ultimate-gdpr-cookie-popup-kichel-white h6 {
  color: #333333;
}
.ct-ultimate-gdpr-cookie-popup-kichel-white #ct-ultimate-gdpr-cookie-read-more {
  color: #d71852;
}
.ct-ultimate-gdpr-cookie-popup-kichel-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

/* END KICHEL */
/* MACARON */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-macaron {
  border-radius: 10px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 35px 43px 51px 43px;
  text-align: center;
  width: 360px;
}
@media screen and (max-width: 666px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-macaron {
    left: 0 !important;
    width: 100%;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-change-settings {
  padding: 11px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject {
  width: 100%;
  margin-left: 0;
  margin-top: 9px;
}

.ct-ultimate-gdpr-cookie-popup-macaron.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-macaron.ct-ultimate-gdpr-cookie-popup-button-rounded #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-macaron.ct-ultimate-gdpr-cookie-popup-button-rounded #ct-ultimate-gdpr-cookie-change-settings {
  border-radius: 5px;
}
.ct-ultimate-gdpr-cookie-popup-macaron .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-macaron .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  left: -25px;
  top: -25px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-macaron .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-macaron.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-standard-settings #ct-ultimate-gdpr-cookie-accept, .ct-ultimate-gdpr-cookie-popup-macaron.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-standard-settings #ct-ultimate-gdpr-cookie-accept {
  margin: 20px 0 0 0;
}
.ct-ultimate-gdpr-cookie-popup-macaron .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-macaron .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  margin: -20px auto -35px auto;
  padding: 20px 0;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-macaron .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-macaron .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept {
    margin: 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto 0 auto;
  text-align: center;
}
.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-content span .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-macaron h1,
.ct-ultimate-gdpr-cookie-popup-macaron h2,
.ct-ultimate-gdpr-cookie-popup-macaron h3,
.ct-ultimate-gdpr-cookie-popup-macaron h4,
.ct-ultimate-gdpr-cookie-popup-macaron h5,
.ct-ultimate-gdpr-cookie-popup-macaron h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 10px 0 15px 0;
  text-align: center;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-change-settings {
  display: inline-block;
  float: none;
  margin: 25px 10px 0 0;
  padding: 11px 15px;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}

.ct-ultimate-gdpr-cookie-popup-macaron-blue .dashicons-no-alt {
  color: #c5c6c8;
}
.ct-ultimate-gdpr-cookie-popup-macaron-blue h1,
.ct-ultimate-gdpr-cookie-popup-macaron-blue h2,
.ct-ultimate-gdpr-cookie-popup-macaron-blue h3,
.ct-ultimate-gdpr-cookie-popup-macaron-blue h4,
.ct-ultimate-gdpr-cookie-popup-macaron-blue h5,
.ct-ultimate-gdpr-cookie-popup-macaron-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-macaron-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-macaron-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-macaron-blue #ct-ultimate-gdpr-cookie-change-settings {
  margin: 9px 0 0 0;
}

.ct-ultimate-gdpr-cookie-popup-macaron-black .dashicons-no-alt {
  color: #c5c6c6;
}
.ct-ultimate-gdpr-cookie-popup-macaron-black h1,
.ct-ultimate-gdpr-cookie-popup-macaron-black h2,
.ct-ultimate-gdpr-cookie-popup-macaron-black h3,
.ct-ultimate-gdpr-cookie-popup-macaron-black h4,
.ct-ultimate-gdpr-cookie-popup-macaron-black h5,
.ct-ultimate-gdpr-cookie-popup-macaron-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-macaron-black #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-macaron-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-macaron-black #ct-ultimate-gdpr-cookie-change-settings {
  margin: 9px 0 0 0;
}

.ct-ultimate-gdpr-cookie-popup-macaron-white .dashicons-no-alt {
  color: #a9a9a9;
}
.ct-ultimate-gdpr-cookie-popup-macaron-white h1,
.ct-ultimate-gdpr-cookie-popup-macaron-white h2,
.ct-ultimate-gdpr-cookie-popup-macaron-white h3,
.ct-ultimate-gdpr-cookie-popup-macaron-white h4,
.ct-ultimate-gdpr-cookie-popup-macaron-white h5,
.ct-ultimate-gdpr-cookie-popup-macaron-white h6 {
  color: #666666;
}
.ct-ultimate-gdpr-cookie-popup-macaron-white #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-macaron-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-popup-macaron-white #ct-ultimate-gdpr-cookie-change-settings {
  margin: 9px 0 0 0;
}

/* END MACARON */
/* WIBELE */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wibele {
  background-image: url(images/cookie-popup-wibele-blue-black-bg.png);
  background-repeat: repeat;
  box-shadow: none;
  box-sizing: border-box;
  padding: 35px 40px;
  text-align: center;
  width: 560px;
}
@media screen and (max-width: 567px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wibele {
    left: 0 !important;
    width: 100%;
  }
}

.ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-close {
  position: relative;
}
.ct-ultimate-gdpr-cookie-popup-wibele .dashicons-no-alt {
  -webkit-transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  left: -25px;
  top: -25px;
  transition: all 0.3s ease-in-out;
}
.ct-ultimate-gdpr-cookie-popup-wibele .dashicons-no-alt:hover {
  color: #fff;
}
.ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-topPanel,
.ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-bottomPanel {
  margin: -20px auto -35px auto;
  max-width: 1050px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-content,
  .ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-content {
    width: 60%;
  }
  .ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper,
  .ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    text-align: right;
    width: 40%;
  }
  .ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-topPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-wibele .ct-ultimate-gdpr-cookie-popup-bottomPanel #ct-ultimate-gdpr-cookie-accept {
    margin: 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content {
  font-family: Hind, sans-serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0 auto 0 auto;
  text-align: center;
}
.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content span {
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 0 8px;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content span .fa {
  font-family: FontAwesome;
  font-size: 9px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}
.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  border: none;
  display: inline;
  float: none;
  font-family: montserrat_bold, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 7px;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}
.ct-ultimate-gdpr-cookie-popup-wibele h1,
.ct-ultimate-gdpr-cookie-popup-wibele h2,
.ct-ultimate-gdpr-cookie-popup-wibele h3,
.ct-ultimate-gdpr-cookie-popup-wibele h4,
.ct-ultimate-gdpr-cookie-popup-wibele h5,
.ct-ultimate-gdpr-cookie-popup-wibele h6 {
  font-size: 18px;
  font-weight: normal;
  margin: 0 0 10px 0;
  text-align: center;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-wibele #ct_ultimate-gdpr-cookie-reject {
  display: inline-block;
  float: none;
  margin: 0 0 0 10px;
  padding: 11px 15px;
  border-width: 2px !important;
  width: 100%;
}
@media screen and (min-width: 568px) {
  .ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-wibele #ct_ultimate-gdpr-cookie-reject {
    max-width: 65%;
  }
}
.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-read-more {
  display: none;
}

.ct-ultimate-gdpr-cookie-popup-wibele-blue .dashicons-no-alt {
  color: #c5c6c8;
}
.ct-ultimate-gdpr-cookie-popup-wibele-blue h1,
.ct-ultimate-gdpr-cookie-popup-wibele-blue h2,
.ct-ultimate-gdpr-cookie-popup-wibele-blue h3,
.ct-ultimate-gdpr-cookie-popup-wibele-blue h4,
.ct-ultimate-gdpr-cookie-popup-wibele-blue h5,
.ct-ultimate-gdpr-cookie-popup-wibele-blue h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-wibele-blue #ct-ultimate-gdpr-cookie-read-more {
  color: #369ee3;
}
.ct-ultimate-gdpr-cookie-popup-wibele-blue .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

.ct-ultimate-gdpr-cookie-popup-wibele-black .dashicons-no-alt {
  color: #c5c6c6;
}
.ct-ultimate-gdpr-cookie-popup-wibele-black h1,
.ct-ultimate-gdpr-cookie-popup-wibele-black h2,
.ct-ultimate-gdpr-cookie-popup-wibele-black h3,
.ct-ultimate-gdpr-cookie-popup-wibele-black h4,
.ct-ultimate-gdpr-cookie-popup-wibele-black h5,
.ct-ultimate-gdpr-cookie-popup-wibele-black h6 {
  color: #e5e5e5;
}
.ct-ultimate-gdpr-cookie-popup-wibele-black #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-wibele-black .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-popup-wibele-white {
  background-image: url(images/cookie-popup-wibele-white-bg.png);
  background-repeat: repeat;
}

.ct-ultimate-gdpr-cookie-popup-wibele-white .dashicons-no-alt {
  color: #a9a9a9;
}
.ct-ultimate-gdpr-cookie-popup-wibele-white h1,
.ct-ultimate-gdpr-cookie-popup-wibele-white h2,
.ct-ultimate-gdpr-cookie-popup-wibele-white h3,
.ct-ultimate-gdpr-cookie-popup-wibele-white h4,
.ct-ultimate-gdpr-cookie-popup-wibele-white h5,
.ct-ultimate-gdpr-cookie-popup-wibele-white h6 {
  color: #333333;
}
.ct-ultimate-gdpr-cookie-popup-wibele-white #ct-ultimate-gdpr-cookie-read-more {
  color: #45bba5;
}
.ct-ultimate-gdpr-cookie-popup-wibele-white .fa {
  font-family: FontAwesome;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

/* END WIBELE */
/* FULL PANEL */
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel {
  top: 50% !important;
  left: 50% !important;
  padding: 70px !important;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel {
    min-width: 879px;
    padding: 120px !important;
  }
}
@media (max-width: 500px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern {
    width: 100% !important;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic .ct-ultimate-gdpr-cookie-buttons, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern .ct-ultimate-gdpr-cookie-buttons {
  padding: 0;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-change-settings,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-read-more, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-read-more {
  width: auto;
  min-width: 165px;
}
@media (min-width: 768px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-change-settings,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-read-more, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-read-more {
    max-width: 165px;
  }
}
@media (min-width: 768px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-accept, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept {
    float: left;
  }
}
@media (max-width: 767px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-accept, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept {
    margin-bottom: 15px !important;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-change-settings, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings {
  float: right;
  clear: none;
}
@media (min-width: 768px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-change-settings, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings {
    margin-right: 15px !important;
  }
}
@media (max-width: 767px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-classic #ct-ultimate-gdpr-cookie-read-more, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-read-more {
    margin-bottom: 15px !important;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern {
  width: 500px;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct_ultimate-gdpr-cookie-reject {
  margin-bottom: 10px !important;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct_ultimate-gdpr-cookie-reject {
    margin-bottom: 20px !important;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings,
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-read-more {
  margin-right: 10px !important;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings,
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-read-more {
    margin-right: 0px !important;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 0;
  margin-left: 0;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-classic-blue #ct-ultimate-gdpr-cookie-change-settings, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-classic-light #ct-ultimate-gdpr-cookie-change-settings {
  min-width: 165px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-classic-blue #ct_ultimate-gdpr-cookie-reject, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-classic-light #ct_ultimate-gdpr-cookie-reject {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-classic-blue #ct_ultimate-gdpr-cookie-reject a, #ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-classic-light #ct_ultimate-gdpr-cookie-reject a {
  color: #e5e5e5 !important;
}
#ct-ultimate-gdpr-cookie-popup.ct-ultimate-gdpr-cookie-fullPanel .ct-container {
  width: 100%;
  padding: 0;
}

.ct-ultimate-gdpr-cookie-fullPanel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 9;
}
.ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-content {
  float: none;
  width: auto;
  padding: 0 0 80px;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-content #ct-ultimate-gdpr-cookie-read-more {
  display: block;
  margin: 0;
}
.ct-ultimate-gdpr-cookie-fullPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  padding: 0;
  text-align: center;
}
.ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-change-settings {
  float: none;
  width: auto;
  margin: 0;
}
@media (min-width: 768px) {
  .ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-change-settings {
    min-width: 165px;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-accept {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-accept {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .ct-ultimate-gdpr-cookie-fullPanel #ct-ultimate-gdpr-cookie-change-settings {
    margin-left: 20px;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel #ct_ultimate-gdpr-cookie-reject {
  margin-top: 0 !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel #ct_ultimate-gdpr-cookie-reject {
    margin-bottom: 20px;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-classic-blue #ct_ultimate-gdpr-cookie-reject {
  margin-left: 13px;
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-modern #ct_ultimate-gdpr-cookie-reject {
  margin-bottom: 0 !important;
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-apas #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-apas #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 10px !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-apas #ct_ultimate-gdpr-cookie-reject {
    margin-bottom: 20px;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-apas #ct_ultimate-gdpr-cookie-reject a {
  color: #e5e5e5 !important;
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 0 !important;
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings {
  width: auto !important;
  margin-top: 10px !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct_ultimate-gdpr-cookie-reject,
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings {
    width: 100% !important;
  }
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct_ultimate-gdpr-cookie-reject {
    margin-bottom: 20px;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-10-set #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 10px !important;
  width: auto !important;
  padding: 10px 20px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-10-set #ct_ultimate-gdpr-cookie-reject,
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-10-set #ct-ultimate-gdpr-cookie-change-settings {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct_ultimate-gdpr-cookie-reject {
  float: none !important;
  margin-right: 0 !important;
  padding: 6px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct_ultimate-gdpr-cookie-reject {
    margin-bottom: 20px !important;
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
  max-width: 207px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
    width: 100% !important;
    max-width: 100%;
  }
}
.ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject {
  width: 23% !important;
  padding: 6px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-fullPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject {
    width: 100% !important;
  }
}

@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-read-more {
    width: 100%;
  }
}

#ct-ultimate-gdpr-cookie-popup #ct-ultimate-cookie-close-modal {
  color: #fff;
  text-decoration: none;
  display: block;
  font-family: montserrat_semibold, sans-serif;
}
#ct-ultimate-gdpr-cookie-popup #ct-ultimate-cookie-close-modal:hover {
  opacity: 0.8;
}
#ct-ultimate-gdpr-cookie-popup .close-modal-icon {
  position: absolute;
  right: 0;
  top: 0;
}
#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons {
  display: flex;
  flex-wrap: wrap;
}
#ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons .cookie-buttons {
  margin-bottom: 10px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-cookie-popup .ct-ultimate-gdpr-cookie-buttons .cookie-buttons {
    width: 100%;
    margin: 5px 0 !important;
    text-align: center;
  }
}
#ct-ultimate-gdpr-cookie-popup[style*="background-color: rgb(255, 255, 255)"] #ct-ultimate-cookie-close-modal {
  color: #808080;
}

/* END FULL PANEL */
/* BOTTOM & TOP PANEL */
.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-bottomPanel #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-topPanel #ct_ultimate-gdpr-cookie-reject {
  float: left;
  border: 1px solid;
  margin-left: 10px;
  margin-top: 20px;
  font-size: 12px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-bottomPanel #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-topPanel #ct_ultimate-gdpr-cookie-reject {
    float: none;
    margin-left: 0;
    text-align: center;
  }
}
.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-classic-blue #ct_ultimate-gdpr-cookie-reject #ct-ultimate-cookie-close-modal, .ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-classic-blue #ct_ultimate-gdpr-cookie-reject #ct-ultimate-cookie-close-modal {
  padding: 1px 20px;
}
.ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-classic-light #ct_ultimate-gdpr-cookie-reject #ct-ultimate-cookie-close-modal, .ct-ultimate-gdpr-cookie-popup-classic.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-classic-light #ct_ultimate-gdpr-cookie-reject #ct-ultimate-cookie-close-modal {
  padding: 1px 20px;
}

.ct-ultimate-gdpr-cookie-popup-modern #ct_ultimate-gdpr-cookie-reject {
  border: 1px solid;
  margin-right: 10px;
  padding: 5px;
}
.ct-ultimate-gdpr-cookie-popup-modern #ct-ultimate-gdpr-cookie-accept {
  margin-right: 10px;
}

.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-content {
  width: 52% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel #ct-ultimate-gdpr-cookie-content {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  width: 48% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject {
  display: inline-block;
  margin: 0 0 0 12px;
  text-align: center;
  color: #e5e5e5;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject {
    margin-left: 0;
    margin-top: 10px;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject {
  width: auto;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel .ct-ultimate-gdpr-cookie-popup-btn-wrapper #ct_ultimate-gdpr-cookie-reject {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct_ultimate-gdpr-cookie-reject {
  margin-top: 24px;
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-accept, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-accept {
  margin-top: 24px;
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kahk #ct-ultimate-gdpr-cookie-change-settings {
  margin-left: 0;
  margin-top: 25px !important;
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content {
  width: 46% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-content {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  width: 43% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject {
  padding-top: 0;
  padding-bottom: 0;
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject #ct-ultimate-cookie-close-modal, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject #ct-ultimate-cookie-close-modal {
  padding: 0 !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    padding: 0 30px;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject {
  width: auto;
  margin-top: 30px;
  float: none;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct-ultimate-gdpr-cookie-change-settings,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject {
    margin: 5px 0;
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-oreo #ct_ultimate-gdpr-cookie-reject {
  margin-right: 10px;
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content {
  width: 46% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-content {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  width: 54% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct-ultimate-gdpr-cookie-change-settings,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject {
  width: auto;
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject {
  margin: 25px 0 0 10px;
  padding: 11px 20px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wafer #ct_ultimate-gdpr-cookie-reject {
    margin-left: 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject {
  margin: 19px 0 0 5px;
  padding-top: 11px;
  padding-bottom: 11px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-jumble #ct_ultimate-gdpr-cookie-reject {
    margin-left: 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject {
  margin: 19px 0 0 5px;
  padding: 11px 20px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-khapse #ct_ultimate-gdpr-cookie-reject, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-tareco #ct_ultimate-gdpr-cookie-reject {
    margin-left: 0;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content {
  width: 43% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-content {
    width: 85% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  width: 46% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kichel .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    width: 75% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
  margin-top: 0;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-kichel #ct-ultimate-gdpr-cookie-change-settings {
    margin-bottom: 10px;
  }
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron .ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron .ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    width: 85% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept {
  margin-top: 9px;
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject,
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-change-settings {
  float: none !important;
  width: auto !important;
  padding: 11px 20px;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-accept,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct_ultimate-gdpr-cookie-reject,
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-macaron #ct-ultimate-gdpr-cookie-change-settings {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content {
  width: 48% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-content {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-popup-btn-wrapper {
  width: 52% !important;
}
@media (max-width: 566px) {
  .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-popup-btn-wrapper, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-popup-btn-wrapper {
    width: 100% !important;
  }
}
.ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-bottomPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-change-settings, .ct-ultimate-gdpr-cookie-popup-10-set.ct-ultimate-gdpr-cookie-topPanel.ct-ultimate-gdpr-cookie-popup-wibele #ct-ultimate-gdpr-cookie-change-settings {
  width: 32%;
}

/* END BOTTOM & TOP PANEL */
/* END COOKIE POPUP */
/* WITHDRAWAL COOKIE AGREEMENT */
#ct-ultimate-gdpr-withdrawal-cookie-agreement {
  border: 1px solid #e6e6e6;
  padding: 2rem;
}
#ct-ultimate-gdpr-withdrawal-cookie-agreement h4 {
  margin-bottom: 1.5rem;
}
#ct-ultimate-gdpr-withdrawal-cookie-agreement label {
  position: relative;
  display: inline-block;
}
#ct-ultimate-gdpr-withdrawal-cookie-agreement label .ct-ultimate-gdpr-block-cookies-checkbox {
  position: absolute;
  left: 25px;
  top: 0px;
}
#ct-ultimate-gdpr-withdrawal-cookie-agreement label .description {
  padding-left: 1rem;
}
#ct-ultimate-gdpr-withdrawal-cookie-agreement .result-notification {
  padding: 15px 20px;
  margin: 15px 0;
  border: 1px solid #c3e6cb;
  border-radius: 2px;
  background-color: #d4edda;
  color: #155724;
}
#ct-ultimate-gdpr-withdrawal-cookie-agreement .ct-ultimate-gdpr-cookie-block-btn {
  margin: 10px 0 0 0;
  padding: 13px 28px;
}
@media (max-width: 566px) {
  #ct-ultimate-gdpr-withdrawal-cookie-agreement .ct-ultimate-gdpr-cookie-block-btn {
    text-align: center;
  }
}

/* END OF WITHDRAWAL COOKIE AGREEMENT */
/* SINGLE FORM */
#ct-ultimate-gdpr-cookie-modal-single-form {
  margin: 0;
  position: relative;
}

.ct-ultimate-gdpr-cookie-modal-single {
  list-style: none;
  text-align: center;
  justify-content: space-around;
  margin: 0;
  padding: 0 0 50px 0;
  width: 100%;
}
.ct-ultimate-gdpr-cookie-modal-single ul {
  display: flex;
  padding: 20px 0 50px;
  margin: 0;
  justify-content: center;
}
.ct-ultimate-gdpr-cookie-modal-single li {
  text-align: center;
  width: 20%;
  margin-left: 0 !important;
  margin: 0;
  list-style: none;
}
.ct-ultimate-gdpr-cookie-modal-single li:hover {
  cursor: pointer;
}
.ct-ultimate-gdpr-cookie-modal-single li div {
  background: #fafafa;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 5px;
}
@media (min-width: 480px) {
  .ct-ultimate-gdpr-cookie-modal-single li div {
    width: 80px;
    height: 80px;
  }
}
.ct-ultimate-gdpr-cookie-modal-single li svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: block;
  margin: 0 auto;
  max-width: 50%;
}
@media (min-width: 480px) {
  .ct-ultimate-gdpr-cookie-modal-single li svg {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 575px) {
  .ct-ultimate-gdpr-cookie-modal-single li svg {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 480px) {
  .ct-ultimate-gdpr-cookie-modal-single li svg {
    width: 20px;
    height: 20px;
  }
}
.ct-ultimate-gdpr-cookie-modal-single li svg path {
  fill: #595959;
}
.ct-ultimate-gdpr-cookie-modal-single li label {
  display: block;
  color: #595959;
  background-color: #fafafa;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  position: relative;
  border: 3px solid #fafafa;
}
@media (max-width: 575px) {
  .ct-ultimate-gdpr-cookie-modal-single li label {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 480px) {
  .ct-ultimate-gdpr-cookie-modal-single li label {
    width: 40px;
    height: 40px;
  }
}
.ct-ultimate-gdpr-cookie-modal-single li.active label {
  border: 3px solid #82aa3b;
}
.ct-ultimate-gdpr-cookie-modal-single li.active svg {
  fill: rgb(130, 170, 59);
}
.ct-ultimate-gdpr-cookie-modal-single li.active svg path {
  fill: rgb(130, 170, 59) !important;
}
.ct-ultimate-gdpr-cookie-modal-single li span {
  display: block;
  font-size: 13px;
  margin-top: 15px;
  color: #595959;
}
@media (max-width: 480px) {
  .ct-ultimate-gdpr-cookie-modal-single li span {
    font-size: 9px;
  }
}
.ct-ultimate-gdpr-cookie-modal-single input {
  margin-top: 10px;
}
.ct-ultimate-gdpr-cookie-modal-single [type=radio], .ct-ultimate-gdpr-cookie-modal-single [type=checkbox] {
  outline: none;
}
.ct-ultimate-gdpr-cookie-modal-single [type=checkbox] {
  border: 2px solid rgba(0, 0, 0, 0.3);
}
.ct-ultimate-gdpr-cookie-modal-single [type=checkbox]::after {
  border: 3px solid rgb(130, 170, 59);
  border-top: 0;
  border-left: 0;
  left: 8px;
  top: 3px;
  transform: rotate(40deg);
}
.ct-ultimate-gdpr-cookie-modal-single [type=radio] {
  border: 2px solid rgba(0, 0, 0, 0.3);
  width: 22px;
  height: 22px;
  padding: 0;
}
.ct-ultimate-gdpr-cookie-modal-single [type=radio]::after {
  background: rgb(130, 170, 59);
  left: 5px;
  top: 5px;
}
.ct-ultimate-gdpr-cookie-modal-single [type=radio]:checked {
  background: url("images/icons8_Delete_1.png");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.ct-ultimate-gdpr-cookie-modal-single .icon-border-fill {
  border: 2px solid #82aa3b;
}
.ct-ultimate-gdpr-cookie-modal-single .icon-border-fill path {
  fill: #82aa3b !important;
}
.ct-ultimate-gdpr-cookie-modal-single .icon-border-unfill {
  border: 2px solid #595959;
}
.ct-ultimate-gdpr-cookie-modal-single .icon-border-unfill path {
  fill: #595959 !important;
}

.ct-ultimate-gdpr-cookie-modal-single-item {
  background-color: transparent;
}

.ct-ultimate-gdpr-cookie-modal-single-wrap__inner--title {
  width: 100%;
  height: auto;
}
.ct-ultimate-gdpr-cookie-modal-single-wrap__inner--title h4 {
  font-weight: 700;
  text-align: left;
  margin: 0 0 15px 0;
  font-family: open_sans_bold, sans-serif;
  font-size: 15px;
  color: #333;
  letter-spacing: normal;
  text-transform: none;
}
.ct-ultimate-gdpr-cookie-modal-single-wrap__inner--title .title-block {
  float: left;
  width: 50%;
}
@media (max-width: 992px) {
  .ct-ultimate-gdpr-cookie-modal-single-wrap__inner--title .title-block {
    width: 50%;
  }
}
.ct-ultimate-gdpr-cookie-modal-single-wrap__inner--info {
  width: 100%;
  height: auto;
}
.ct-ultimate-gdpr-cookie-modal-single-wrap__inner--info .ct-ultimate-gdpr-cookie-modal-single__info--desc {
  float: left;
  width: 50%;
}
.ct-ultimate-gdpr-cookie-modal-single-wrap__inner--info-temp {
  display: none;
}

#desc-left li {
  display: none;
}

.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-single li {
  background: rgba(242, 242, 242, 0.5);
  padding: 35px 0;
  border: 2px solid rgba(34, 49, 86, 0);
  margin: 0 5px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-single li label {
  border: 2px solid rgba(242, 242, 242, 0.5);
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-single li.active {
  background: #f2f2f2;
  border-radius: 2px;
  border: 2px solid #ccc;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-single li.active label {
  border: 2px solid #ccc;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-single li.active label path {
  fill: #9599a1 !important;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider-able li:before, .ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-one .ct-ultimate-gdpr-cookie-modal-slider-not-able li:before {
  color: #436621;
  content: "—";
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two {
  padding-left: 0;
  padding-right: 0;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two #ct-ultimate-gdpr-cookie-modal-close {
  margin-right: 20px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two #ct-ultimate-gdpr-cookie-modal-body {
  padding: 0;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-single li {
  -webkit-transition: all 0.75s ease;
  transition: all 0.75s ease;
  background-color: transparent;
  position: relative;
  height: 178px;
  padding-top: 30px;
  margin: 2px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-single li label {
  background-color: transparent;
  border: 0;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-single li:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: 0 auto;
  width: 100%;
  height: 5px;
  border: 0;
  background: #f2f2f2;
  border-radius: 0;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-single li.active {
  background: #f2f2f2;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-single li.active:before {
  background-color: #6a8ee7;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-single li.active label path {
  fill: #878b92 !important;
}
.ct-ultimate-gdpr-cookie-modal-content-single.ct-ultimate-gdpr-cookie-skin-two .ct-ultimate-gdpr-cookie-modal-single-wrap {
  padding: 0 70px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue img, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue img, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green img {
  display: none;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single {
  width: 85%;
  padding: 0 0 20px 0;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single ul, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single ul, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single ul {
  padding-bottom: 0;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single ul li, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single ul li, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single ul li {
  position: relative;
  padding-bottom: 10px;
  text-align: left;
  padding-left: 30px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single ul li input, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single ul li input, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single ul li input {
  position: absolute;
  top: 5px;
  left: 8px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single ul li.active, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single ul li.active, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single ul li.active {
  background-color: #f2f2f2;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single ul li span, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single ul li span, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single ul li span {
  margin-top: 12px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single label, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single label, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single label {
  height: 0;
  width: 0;
  border: 0;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-single-wrap__inner--info, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-single-wrap__inner--info, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-single-wrap__inner--info {
  height: 140px;
  overflow-y: scroll;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-dark-blue .ct-ultimate-gdpr-cookie-modal-btn, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-btn, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-btn {
  display: block;
  top: 151px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-btn, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-btn {
  top: 130px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-able li, .ct-ultimate-gdpr-cookie-modal-content-single.compact-light-blue .ct-ultimate-gdpr-cookie-modal-slider-not-able li, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-slider-able li, .ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-slider-not-able li {
  padding-left: 20px;
}
.ct-ultimate-gdpr-cookie-modal-content-single.compact-green .ct-ultimate-gdpr-cookie-modal-btn {
  top: 130px;
}

.klaro .cookie-modal .cm-modal .cm-header, .klaro .cookie-modal .cm-modal .cm-body, .klaro .cookie-modal .cm-modal .cm-footer {
  padding: 20px !important;
}

.klaro .cookie-modal .cm-modal .cm-header h1 {
  text-align: center;
}
.klaro .cookie-modal .cm-modal .cm-header h1 span u {
  font-weight: 500;
  line-height: normal !important;
  font-family: montserrat_bold, sans-serif;
  letter-spacing: normal;
  color: #595959;
  text-decoration: none;
}

.klaro .cookie-modal .cm-modal .cm-body {
  overflow-y: auto !important;
}

.klaro .cookie-modal p {
  font-family: Hind, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-transform: none;
  color: #797979 !important;
}

@media (min-width: 660px) {
  .klaro .cookie-modal .cm-modal.cm-klaro {
    max-width: 800px !important;
    border-radius: 0 !important;
  }
}

.klaro .cookie-modal .cm-btn {
  border-radius: 0 !important;
  font-family: montserrat_bold, sans-serif !important;
  padding: 10px 15px !important;
  font-size: 15px;
}

.ct-ultimate-gdpr-cookie-popup-classic .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-classic .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-classic .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-classic .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-classic .cm-btn.cm-btn-info {
  background-color: #ff7d27 !important;
}

.ct-ultimate-gdpr-cookie-popup-classic a {
  color: #ff7d27 !important;
}

.ct-ultimate-gdpr-cookie-classic-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-classic-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-classic-blue .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-classic-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-classic-blue .cm-btn.cm-btn-info {
  background-color: #6a8ee7 !important;
}

.ct-ultimate-gdpr-cookie-classic-blue a {
  color: #6a8ee7 !important;
}

.ct-ultimate-gdpr-cookie-classic-light .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-classic-light .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-classic-light .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-classic-light .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-classic-light .cm-btn.cm-btn-info {
  background-color: #6a8ee7 !important;
}

.ct-ultimate-gdpr-cookie-classic-light a {
  color: #6a8ee7 !important;
}

.ct-ultimate-gdpr-cookie-popup-modern .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-modern .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-modern .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-modern .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-modern .cm-btn.cm-btn-info {
  background-color: #ff7d27 !important;
}

.ct-ultimate-gdpr-cookie-popup-apas-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-blue .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-apas-blue .cm-btn.cm-btn-info {
  background-color: #369ee3 !important;
}

.ct-ultimate-gdpr-cookie-popup-apas-blue a {
  color: #369ee3 !important;
}

.ct-ultimate-gdpr-cookie-popup-apas-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-black .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-apas-black .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-apas-black a {
  color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-apas-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-white .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-apas-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-apas-white .cm-btn.cm-btn-info {
  background-color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-apas-white a {
  color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-kahk-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-blue .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-kahk-blue .cm-btn.cm-btn-info {
  background-color: rgb(49, 54, 65) !important;
}

.ct-ultimate-gdpr-cookie-popup-kahk-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-black .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-kahk-black .cm-btn.cm-btn-info {
  background-color: rgb(68, 180, 159) !important;
}

.ct-ultimate-gdpr-cookie-popup-kahk-black a {
  color: rgb(68, 180, 159) !important;
}

.ct-ultimate-gdpr-cookie-popup-kahk-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-white .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kahk-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-kahk-white .cm-btn.cm-btn-info {
  background-color: rgb(68, 180, 159) !important;
}

.ct-ultimate-gdpr-cookie-popup-kahk-white a {
  color: rgb(68, 180, 159) !important;
}

.ct-ultimate-gdpr-cookie-popup-oreo-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-blue .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-oreo-blue .cm-btn.cm-btn-info {
  background-color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-oreo-blue a {
  color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-oreo-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-black .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-oreo-black .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-oreo-black a {
  color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-oreo-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-white .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-oreo-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-oreo-white .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-oreo-white a {
  color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-wafer-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-blue .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-wafer-blue .cm-btn.cm-btn-info {
  background-color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-wafer-blue a {
  color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-wafer-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-black .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-wafer-black .cm-btn.cm-btn-info {
  background-color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-wafer-black a {
  color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-wafer-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-white .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wafer-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-wafer-white .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-wafer-white a {
  color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-jumble-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-blue .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-jumble-blue .cm-btn.cm-btn-info {
  background-color: rgb(54, 158, 227) !important;
  background-image: linear-gradient(to right, rgb(77, 109, 192), rgb(54, 158, 227)) !important;
}

.ct-ultimate-gdpr-cookie-popup-jumble-blue a {
  color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-jumble-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-black .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-jumble-black .cm-btn.cm-btn-info {
  background-color: rgb(120, 137, 181) !important;
  background-image: linear-gradient(to right, rgb(107, 125, 172), rgb(131, 147, 188)) !important;
}

.ct-ultimate-gdpr-cookie-popup-jumble-black a {
  color: rgb(120, 137, 181) !important;
}

.ct-ultimate-gdpr-cookie-popup-jumble-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-white .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-jumble-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-jumble-white .cm-btn.cm-btn-info {
  background-color: rgb(70, 188, 185) !important;
  background-image: linear-gradient(to right, rgb(73, 190, 224), rgb(69, 187, 165)) !important;
}

.ct-ultimate-gdpr-cookie-popup-jumble-white a {
  color: rgb(70, 188, 185) !important;
}

.ct-ultimate-gdpr-cookie-popup-khapse-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-blue .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-khapse-blue .cm-btn.cm-btn-info {
  background-color: rgb(222, 120, 52) !important;
}

.ct-ultimate-gdpr-cookie-popup-khapse-blue a {
  color: rgb(222, 120, 52) !important;
}

.ct-ultimate-gdpr-cookie-popup-khapse-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-black .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-khapse-black .cm-btn.cm-btn-info {
  background-color: rgb(222, 120, 52) !important;
}

.ct-ultimate-gdpr-cookie-popup-khapse-black a {
  color: rgb(222, 120, 52) !important;
}

.ct-ultimate-gdpr-cookie-popup-khapse-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-white .cm-list-input.required:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-khapse-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-khapse-white .cm-btn.cm-btn-info {
  background-color: rgb(222, 120, 52) !important;
}

.ct-ultimate-gdpr-cookie-popup-khapse-white a {
  color: rgb(222, 120, 52) !important;
}

.ct-ultimate-gdpr-cookie-popup-tareco-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-tareco-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-tareco-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-tareco-blue .cm-btn.cm-btn-info {
  background-color: rgb(49, 106, 177) !important;
}

.ct-ultimate-gdpr-cookie-popup-tareco-blue a {
  color: rgb(49, 106, 177) !important;
}

.ct-ultimate-gdpr-cookie-popup-tareco-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-tareco-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-tareco-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-tareco-black .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-tareco-black a {
  color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-tareco-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-tareco-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-tareco-white .cm-btn.cm-btn-info {
  background-color: rgb(49, 106, 177) !important;
}

.ct-ultimate-gdpr-cookie-popup-tareco-white a {
  color: rgb(49, 106, 177) !important;
}

.ct-ultimate-gdpr-cookie-popup-kichel-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kichel-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kichel-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-kichel-blue .cm-btn.cm-btn-info {
  background-color: #369ee3 !important;
}

.ct-ultimate-gdpr-cookie-popup-kichel-blue a {
  color: #369ee3 !important;
}

.ct-ultimate-gdpr-cookie-popup-kichel-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kichel-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kichel-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-kichel-black .cm-btn.cm-btn-info {
  background-color: rgb(81, 151, 30) !important;
}

.ct-ultimate-gdpr-cookie-popup-kichel-black a {
  color: rgb(81, 151, 30) !important;
}

.ct-ultimate-gdpr-cookie-popup-kichel-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kichel-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-kichel-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-kichel-white .cm-btn.cm-btn-info {
  background-color: rgb(215, 24, 82) !important;
}

.ct-ultimate-gdpr-cookie-popup-kichel-white a {
  color: rgb(215, 24, 82) !important;
}

.ct-ultimate-gdpr-cookie-popup-macaron-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-macaron-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-macaron-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-macaron-blue .cm-btn.cm-btn-info {
  background-color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-macaron-blue a {
  color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-macaron-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-macaron-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-macaron-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-macaron-black .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-macaron-black a {
  color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-macaron-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-macaron-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-macaron-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-macaron-white .cm-btn.cm-btn-info {
  background-color: #45bba5 !important;
}

.ct-ultimate-gdpr-cookie-popup-macaron-white a {
  color: #45bba5 !important;
}

.ct-ultimate-gdpr-cookie-popup-wibele-blue .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wibele-blue .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wibele-blue .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-wibele-blue .cm-btn.cm-btn-info {
  background-color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-wibele-blue a {
  color: rgb(54, 158, 227) !important;
}

.ct-ultimate-gdpr-cookie-popup-wibele-black .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wibele-black .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wibele-black .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-wibele-black .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-wibele-black a {
  color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-wibele-white .cm-list-input:checked + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wibele-white .cm-list-input.only-required + .cm-list-label .slider, .ct-ultimate-gdpr-cookie-popup-wibele-white .cm-btn.cm-btn-success, .ct-ultimate-gdpr-cookie-popup-wibele-white .cm-btn.cm-btn-info {
  background-color: rgb(69, 187, 165) !important;
}

.ct-ultimate-gdpr-cookie-popup-wibele-white a {
  color: rgb(69, 187, 165) !important;
}

#ct-ultimate-gdpr-loader:after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url("images/ajax-loader.gif");
}

.ct-loading {
  position: relative;
}

.ct-loading:before {
  content: "";
  min-width: 32px;
  min-height: 32px;
  background-repeat: no-repeat;
  background-image: url("images/ajax-loader.gif");
  position: absolute;
  top: 20px;
  right: 20px;
}